import axios from '@dataServices/axios'
import axiosLib from 'axios'
import { computed, observable, reaction, makeObservable, runInAction, action } from 'mobx'
import { find, isEmpty, isEqual, isNull, isObject, isUndefined, last, maxBy, omitBy, sortBy } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import AssignmentResponse from '@models/AssignmentResponse'
import CourseStepReview from '@models/CourseStepReview'
import AssignmentQuestionResponse from '@models/AssignmentQuestionResponse'
import QuestionReviewerComments from '@dataServices/QuestionReviewerComments'
import AssignmentResponses from '@dataServices/AssignmentResponses'

class WebWorksheetsDataService {
  _assignment = null
  _allAssignmentResponses = null
  _allCourseStepReviews = null
  _currentAssignmentResponseId = null
  _currentAssignmentResponseData = null
  _questionReviewerComments = null
  _questionData = null
  _courseStepReview = null
  _lastSavedAt = null
  _currentAssignmentResponseIndex = 0
  _parent = null
  _reactionStopFn = null

  SKIP_ASSIGNMENT_RESPONSE_REACTION = false

  constructor (parent, preload = {}) {
    makeObservable(this, {
      _assignment: observable,
      _allAssignmentResponses: observable,
      _allCourseStepReviews: observable,
      _currentAssignmentResponseId: observable,
      _currentAssignmentResponseData: observable,
      _questionReviewerComments: observable,
      _questionData: observable,
      _courseStepReview: observable,
      _lastSavedAt: observable,
      _currentAssignmentResponseIndex: observable,
      assignment: computed,
      latestAssignmentResponse: computed,
      assignmentResponse: computed,
      questionReviewerComments: computed,
      allAssignmentResponses: computed,
      courseStepReview: computed,
      assignmentHtmlFragment: computed,
      assignmentHtmlUrl: computed,
      questionData: computed,
      setAssignmentResponseId: action,
    })

    this._parent = parent
    if (preload.assignment) {
      this._assignment = preload.assignment
    }

    this._reactionStopFn = reaction(() => this._currentAssignmentResponseId, val => {
      if (!this.SKIP_ASSIGNMENT_RESPONSE_REACTION) {
        const intv = setInterval(() => {
          if (this.assignment) {
            this.requestCurrentAssignmentResponse()
            this.requestQuestionReviewerComments()
            clearInterval(intv)
          }
        }, 100)
      }
    })

    // Must go after the reaction is set up so the data will be fetched.
    if (preload.assignmentResponseId) {
      this._currentAssignmentResponseId = preload.assignmentResponseId
    }
  }

  dispose () {
    this._reactionStopFn && this._reactionStopFn()
  }

  get groupId () {
    return this._parent.groupId
  }

  get assignmentId () {
    return this._parent.assignmentId
  }

  get assignmentRefId () {
    return this._parent.assignmentRefId
  }

  get assignmentResponseId () {
    return this.assignmentResponse && this.assignmentResponse.id
  }

  setAssignmentResponseId (id) {
    this._currentAssignmentResponseId = id
    // this._recalculateQuestionData(true)
    // const idx = findIndex(this.allAssignmentResponses, { id })
    // console.log('SETTER', id, idx)
    // if (idx > 0) {
    //   this._currentAssignmentResponseIndex = idx
    //   this._recalculateQuestionData(true)
    // }
  }

  get courseStepId () {
    return this._parent.courseStepId
  }

  get userId () {
    return this._parent.userId
  }

  get assignment() {
    return this._assignment
  }

  get latestAssignmentResponse() {
    return this.allAssignmentResponses[this.allAssignmentResponses.length - 1]
  }

  get assignmentResponse() {
    return this._currentAssignmentResponseData
  }

  get questionReviewerComments() {
    return this._questionReviewerComments
  }

  get allAssignmentResponses() {
    return this._allAssignmentResponses
  }

  get courseStepReview() {
    return this._courseStepReview
  }

  get assignmentHtmlFragment() {
    return this._assignment.html_fragment
  }

  get assignmentHtmlUrl() {
    const courseContentBaseUrl = this.#getCourseContentBaseUrl()
    const relativePath = this.#getAssignmentRelativePath(this._assignment)
    const result = `${courseContentBaseUrl}/${relativePath}`
    return result
  }

  #getCourseContentBaseUrl(){
    const host = import.meta.env.VITE_COURSE_CONTENT_HOST_URL
    return `${host}/action-steps`
  }

  #getAssignmentRelativePath(assignment){
    const url = assignment.html_url;
    const parts = url.split('/');
    const index = parts.length - 4; // Assumes the structure <host>/<baseFolder>/<component>/<language>/<version>/index.html
    const result = parts.slice(index).join('/');
    return result
  }

  get questionData() {
    return this._questionData
  }

  get baseUrl () {
    return this._parent._renderService.baseUrl
  }

  loadData () {
    return Promise.all([
      this.requestAssignmentData(),
      this.requestAssignmentResponseData(),
      this.requestCourseStepReview(),
    ]).then(() => {
      // this.requestCurrentAssignmentResponse()
      // this._recalculateQuestionData()

      // if there is no AssignmentResponse after loading then init the questions
      if (this.allAssignmentResponses.length === 0) {
        this._recalculateQuestionData()
      }
    })
  }

  requestAssignmentData () {
    let promise = null
    if (this._parent.assignmentId) {
      promise = axios.get(`/assignments/${this.assignmentId}`, {
        baseUrl: this.baseUrl,
      })
    } else {
      promise = axios.get(`/assignments/by_assignment_ref/${this.assignmentRefId}`, {
        params: { group_id: this.groupId },
        baseUrl: this.baseUrl,
      })
    }
    return promise.then(assignment => {
      this._assignment = assignment.data
      return this._assignment
    })
  }

  requestAssignmentRemoteHtml () {
    return axiosLib.get(this.assignmentHtmlUrl, {
      headers: {
        'Content-Type': 'text/plain'
      }
    }).then(r => r.data)
  }

  requestAssignmentResponseData () {

    return AssignmentResponses.getAll({
      ...(this.assignmentRefId && { assignment_reference_name: this.assignmentRefId }),
      ...(!this.assignmentRefId && this.assignmentId && { assignment_id: this.assignmentId }),
      group_id: this.groupId,
      user_id: this.userId,
      include_discarded: true,
    }, { baseUrl: this.baseUrl })
      .then((assignmentResponses) => {
        runInAction(() => {
          this._allAssignmentResponses = sortBy(assignmentResponses, 'created_at')

          const lastAr = last(this._allAssignmentResponses)
          if (!this._currentAssignmentResponseId) {
            this._currentAssignmentResponseId = lastAr ? lastAr.id : null
          }

        })
        return assignmentResponses
      });
  }

  requestCurrentAssignmentResponse () {
    return AssignmentResponses.getById(this._currentAssignmentResponseId, { baseUrl: this.baseUrl })
      .then(ar => {
        runInAction(() => {
          this._currentAssignmentResponseData = ar
          this._lastSavedAt = this._currentAssignmentResponseData.created_at.unix()
          this._recalculateQuestionData(true)
        })
        return ar
      })
  }

  requestCourseStepReview () {
    return axios.get(`/course_step_reviews`, {
      params: {
        course_step_id: this.courseStepId,
        user_id: this.userId,
        group_id: this.groupId,
      },
      baseUrl: this.baseUrl,
    }).then(response => response.data.map(d => new CourseStepReview(d)))
      .then(csrs => {
        this._allCourseStepReviews = csrs
        this._courseStepReview = maxBy(csrs, 'created_at')
        return this._courseStepReview
      })
  }

  requestQuestionReviewerComments () {
    return QuestionReviewerComments.getAll({ assignment_response_id: this._currentAssignmentResponseId })
      .then(comments => {
        runInAction(() => {
          this._questionReviewerComments = sortBy(comments, 'created_at')
        })
        return comments
      })
  }

  createAssignmentQuestionResponse (assignmentResponseId, assignmentQuestionId) {
    return axios.post(`/assignment_question_responses`, {
        assignment_question_response: {
          assignment_response_id: assignmentResponseId,
          assignment_question_id: assignmentQuestionId,
        }
      },
      {
        baseUrl: this.baseUrl,
    }).then(response => new AssignmentQuestionResponse(response.data))
      .then(aqr => {
        this.assignmentResponse.appendAssignmentResponse(aqr)
        this._recalculateQuestionData()
        return aqr
      })
  }

  createQuestionReviewerComment (payload) {
    return QuestionReviewerComments.create(payload)
      .then(comment => {
        this._questionReviewerComments = [...this._questionReviewerComments, comment]
        return comment
      })
  }

  deleteReviewerComment (commentId) {
    return QuestionReviewerComments.remove(commentId)
      .then(qrc => {
        const idx = this._questionReviewerComments.findIndex(x => x.id === commentId)
        this._questionReviewerComments[idx] = qrc
        return qrc
      }, (response) => {
        return Promise.reject(response)
      })
  }

  async saveQuestionResponses () {
    if (this._parent._renderService.disableEditing) {
      return await Promise.reject(false)
    }

    if (!this.assignmentResponse || this.assignmentResponse.isSubmitted ) {
      await this._requestNewAssignmentResponse()
    }

    const dirtyQuestions = this.questionData.filter(q => q.dirty)
    if (dirtyQuestions.length === 0) {
      return await Promise.resolve([])
    }

    return await axios.post(`/assignment_question_responses/bulk_update`, {
        assignment_response_id: this.assignmentResponse.id,
        assignment_question_responses: dirtyQuestions.map(q => {
          let answer;

          if (q.type === 'AssignmentQuestion::Multiselect') {
            answer = q.response.localAnswer.join(',')
          } else if (q.type === 'AssignmentQuestion::Select') {
            answer = q.response.localAnswer.id
          } else if (q.type === 'AssignmentQuestion::List' || q.type === 'AssignmentQuestion::Data') {
            answer = q.response.localAnswerData
          } else {
            answer = q.response.localAnswer
          }

          return {
            assignment_question_id: q.id,
            answer: answer,
            answer_data: answer,
          }
        }),
      },
      {
        baseUrl: this.baseUrl,
      }).then(aqrs => {
      runInAction(() => {
        aqrs.data.forEach(aqr => {
          const localResponse = this.questionData.find(q => q.id === aqr.assignment_question_id)
          if (localResponse) {
            localResponse.response.assignmentQuestionResponseId = aqr.id
            localResponse.response.upstreamAnswer = aqr.answer
            localResponse.response.upstreamAnswerData = aqr.answer_data
          }
          this._lastSavedAt = (new Date()).getTime() / 1000.0
        })
      })
    }, resp => {
      console.error('Error saving question responses', resp)
      return Promise.reject(resp)
    })
  }

  submitAssignmentResponse () {
    if (this._parent._renderService.disableEditing) {
      return Promise.reject()
    }

    return axios.put(`/assignment_responses/${this.assignmentResponseId}/submit`, {},
      {
        baseUrl: this.baseUrl,
      })
      .then(resp => {
        const ar = new AssignmentResponse(resp.data.data, resp.data.included)
        runInAction(() => {
          this._pushNewAssignmentResponse(ar)

          // Use this method of changing the current assignment response because we
          // want `_recalculateQuestionData` to be called with `true` so, the question
          // data gets fully overwritten. See the `_requestNewAssignmentResponse` method
          // for the antithesis of this.
          // this.assignmentResponseId = ar.id
          this._currentAssignmentResponseData = ar
        })
        return this.assignmentResponse
      })
  }

  // Private

  async _requestNewAssignmentResponse () {
    if (this._parent._renderService.disableEditing) {
      return await Promise.reject(false)
    }

    let resp = await axios.post(`/assignment_responses`, {
      assignment_response: {
        assignment_id: this.assignment.id,
        group_id: this.groupId,
      },
    },
    {
      baseUrl: this.baseUrl,
    })

    const ar = new AssignmentResponse(resp.data.data[0], resp.data.included)
    this._pushNewAssignmentResponse(ar)

    // Change the index directly so that we can call `_recalculateQuestionData`
    // manually so the data doesn't get wholly overwritten. We want it to be carried
    // over to the new assignment response record.
    // this._currentAssignmentResponseIndex = this.allAssignmentResponses.length - 1
    this.SKIP_ASSIGNMENT_RESPONSE_REACTION = true
    this._currentAssignmentResponseData = last(this.allAssignmentResponses)
    this._currentAssignmentResponseId = last(this.allAssignmentResponses).id

    this._recalculateQuestionData()
    this.SKIP_ASSIGNMENT_RESPONSE_REACTION = false

    return this.assignmentResponse
  }

  // Push a new assignment response record into the list while handling a few
  // potential problems. This method will ensure that there are no duplicates
  // and that they are sorted by their `created_at` date.
  _pushNewAssignmentResponse (ar) {
    const ids = this.allAssignmentResponses.map(d => d.id).filter(id => !!id)
    const tempArr = ids.map(id => id === ar.id ? ar : find(this.allAssignmentResponses, { id }))
    if (!find(tempArr, { id: ar.id })) {
      tempArr.push(ar)
    }
    this._allAssignmentResponses = sortBy(tempArr, 'created_at')
  }

  _recalculateQuestionData (forceOverwriteData = false) {
    this._questionData = this.assignment.assignment_questions.map(q => this._instantiateQuestionData(q, forceOverwriteData))
  }

  // If `forceOverwriteData` is `true`, it will set the localAnswer and upstreamAnswer
  // to the exact value that is in the AssignmentQuestionResponse record.
  // If it is `false` or `undefined`, the value of upstreamAnswer will be exactly what is in the
  // AssignmentQuestionResponse record, but the localAnswer field will attempt to
  // carry over what is currently in the text field for the given question.
  _instantiateQuestionData (q, forceOverwriteData) {
    const existingResponseLocal = this._questionData && this._questionData.find(lq => lq.id === q.id)
    return {
      uuid: uuid(),
      id: q.id,
      referenceName: q.reference_name,
      type: q.type,
      attributes: this._setAttributesForQuestionType(q),
      metadata: q.metadata,
      response: this._setResponseForQuestion(q, !forceOverwriteData && existingResponseLocal && existingResponseLocal.response),
      saved: false,
      forceDirty: false,
      get dirty () { return this.forceDirty || this.response.dirty },
      get blank () { return this.response.blank },
    }
  }

  _setAttributesForQuestionType (q) {
    if (q.type === 'AssignmentQuestion::LongResponse') {
      return {
        text: q.text,
        help_text: q.help_text,
      }
    } else {
      return {
        text: q.text,
        help_text: q.help_text,
      }
    }
  }

  // "Local" refers to the value that has been typed into the text field for the question.
  // "Upstream" refers to the value that currently exists in the database for the question.
  // The question is considered "dirty" if the local and upstream values do not match.
  _setResponseForQuestion (q, existingResponseData) {
    const questionResponse = this.assignmentResponse
      ? find(this.assignmentResponse.getRelation('assignment_question_responses'), { assignment_question_id: q.id }) || {}
      : {}
    const res = {
      assignmentQuestionResponseId: questionResponse.id,
      localAnswer: questionResponse.answer ? questionResponse.answer : (existingResponseData && existingResponseData.localAnswer) || null,
      upstreamAnswer: questionResponse.answer || null,
      localAnswerData: questionResponse.answer_data ? questionResponse.answer_data : (existingResponseData && existingResponseData.localAnswerData) || null,
      upstreamAnswerData: questionResponse.answer_data,
      get dirty () {
        // console.log('dirty?',
        //   this.localAnswer, this.upstreamAnswer,
        //   cloneDeep(this.localAnswerData), cloneDeep(this.upstreamAnswerData),
        //   !isEqual(omitBy(this.localAnswerData, x => isUndefined(x) || isNull(x)), omitBy(this.upstreamAnswerData, x => isUndefined(x) || isNull(x))),
        //   cloneDeep(omitBy(this.localAnswerData, x => isUndefined(x) || isNull(x))), cloneDeep(omitBy(this.upstreamAnswerData, x => isUndefined(x) || isNull(x))))
        // console.log('blank?', this.blank, this.localAnswer, this.localAnswerData)

        const compUpstream = omitBy(this.upstreamAnswerData, x => isUndefined(x) || isNull(x))
        // if upstream is empty then remove blank values from the local object; stops autosave when the assignment is fresh
        const compLocal = omitBy(this.localAnswerData, x => isUndefined(x) || isNull(x) || (isEmpty(compUpstream) && isEmpty(x)))
        // console.log('d2', this.localAnswer !== this.upstreamAnswer ||
        // !isEqual(compLocal, compUpstream), !isEqual(compLocal, compUpstream), compLocal, compUpstream)
        return this.localAnswer !== this.upstreamAnswer ||
          !isEqual(compLocal, compUpstream)
      },
      get blank () {
        const x = isNull(this.localAnswer) || isEmpty(this.localAnswer) || isUndefined(this.localAnswer)
        const y = isNull(this.localAnswerData) || isEmpty(this.localAnswerData) || isObject(this.localAnswerData) && isEmpty(omitBy(this.localAnswerData, x => isUndefined(x) || isNull(x) || isEmpty(x)))
        return x && y
      },
    }
    return res
  }
}

export default WebWorksheetsDataService


// NOT DIRTY
// LOCAL:    { a: '', }
// UPSTREAM: {}

// DIRTY
// LOCAL:    { a: '', }
// UPSTREAM: { a: '1',}
