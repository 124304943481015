export const Constants = {
  baseApiUrl: '/api/v1'
}

export const WindowVariables = {
  partnerId: window.courseVars.partner_id,
  groupId: window.courseVars.group_id,
  courseStepId: window.courseVars.course_step_id,
  partner: window.courseVars.partner,
  timezone: window.courseVars.timezone,
  userId: window.courseVars.user_id,
  flashMessages: window.courseVars.flash_messages,
  partner: window.courseVars.partner,
  benchmarkChartData: window.courseVars.benchmark_chart_data,
  eventChartData: window.courseVars.event_chart_data,
  stepChartData: window.courseVars.step_chart_data,
  currentUserRoles: window.courseVars.current_user_roles,
  currentCourseId: window.courseVars.current_course_id,
  formSelectOptions: window.courseVars.form_select_options,
  kpiCourseStepIds: window.courseVars.kpi_course_step_ids,
  upcomingMeetings: window.courseVars.upcoming_meetings || [],
  latestStudentPhaseProgress: window.courseVars.latest_student_phase_progress,
  nextPhaseOpensAt: window.courseVars.next_phase_opens_at,
  fgsCourseStep: window.courseVars.fgs_step,
  tags: window.courseVars.tags,
}

import User from '@models/User'
import Group from '@models/Group'

if (window.courseVars.current_user) {
  WindowVariables.currentUser = new User(window.courseVars.current_user)
} else {
  WindowVariables.currentUser = null
}
if (window.courseVars.group) {
  WindowVariables.group = new Group(window.courseVars.group)
} else {
  WindowVariables.group = null
}

export function getGroupIdForAnalytics() {
  // filter for Active GMs and sort by descending GM ID.
  let activeGm = WindowVariables.currentUser && WindowVariables.currentUser.group_memberships?.filter(gm => gm.state === 1).sort((a, b) =>  b.id - a.id)
  activeGm = activeGm && activeGm[0]
  return WindowVariables.groupId || (activeGm && activeGm.group_id ) || undefined
}

export function getProgramIdForAnalytics() {
  // filter for Active GMs and sort by descending GM ID.
  let activeGm = WindowVariables.currentUser && WindowVariables.currentUser.group_memberships?.filter(gm => gm.state === 1).sort((a, b) =>  b.id - a.id)
  activeGm = activeGm && activeGm[0]
  return WindowVariables.group && WindowVariables.group.program_id || (activeGm && activeGm.program_id ) || undefined
}

export function courseStepReviewStatusNumerical (stepType, strStatus) {
  const unikey = `${stepType}|${strStatus}`
  switch (unikey) {
    case 'step|Questions Remaining': return 0
    case 'step|Completed': return 1
    case 'step|Under Review': return 2
    case 'event|Questions Remaining': return 0
    case 'event|Attended': return 1
    case 'event|Under Review': return 2
    case 'benchmark|Did Not Achieve': return 0
    case 'benchmark|Achieved': return 1
    case 'benchmark|Under Review': return 2;
  }
}
