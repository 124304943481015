import Vue from 'vue'

// Beep Beep
const BUS = new Vue()

export default {
  subscribe (event, cb) {
    BUS.$on(event, cb)
    return () => BUS.$off(event, cb)
  },

  dispatch (event, params) {
    return BUS.$emit(event, params)
  }
}
