<template>
  <div class="ww-question"
    :class="{
      'ww-question--with-chrome': vm.withChrome,
      'ww-question--table-header': vm.isTableHeader,
      'ww-question--table-footer': vm.isTableFooter,
     }"
    v-if="vm.canRenderSpecificComponent"
    :id="`question---${this.refId}---${this.subrefId}`">
    <div class="ww-question-inner">
      <div class="question-component-wrapper">
        <div class="question-top" v-if="vm.showQuestionText">
          <div class="question-icon">
            <i class="fa fa-pencil" />
          </div>
          <div class="question-text vue-markdown-container flex-col" :id="vm.uuid">
            <div ref="marked"></div>
            <div ref="help_text"></div>
          </div>
        </div>

        <component :is="vm.componentName" v-bind="vm.props" :key="vm.uuid + subrefId" v-if="vm.canRenderSpecificComponent" />
      </div>
    </div>

    <div class="ww-question-review-comments-wrapper" v-if="vm.showReviewArea">
      <WWQuestionReviewerComments :serviceUuid="serviceUuid" :refId="refId" />
    </div>
  </div>
</template>

<script>
import { marked } from 'marked'
import WebWorksheetsService from '@services/webWorksheets/Service'
import WWShortResponse from '@components/webWorksheets/WWShortResponse.vue'
import WWSelect from '@components/webWorksheets/WWSelect.vue'
import WWMultiselect from '@components/webWorksheets/WWMultiselect.vue'
import WWRadio from '@components/webWorksheets/WWRadio.vue'
import WWList from '@components/webWorksheets/WWList.vue'
import WWLongResponse from '@components/webWorksheets/WWLongResponse.vue'
import WWTableCell from '@components/webWorksheets/WWTableCell.vue'
import WWTableCellSum from '@components/webWorksheets/WWTableCellSum.vue'
import WWTableHeader from '@components/webWorksheets/WWTableHeader.vue'
import WWTableFooter from '@components/webWorksheets/WWTableFooter.vue'
import WWQuestionReviewerComments from '@components/webWorksheets/WWQuestionReviewerComments.vue'

import { action, computed, makeObservable, observable, when } from 'mobx'
import { observer } from 'mobx-vue'

class ViewModel {
  service = null
  options = null
  serviceUuid = null
  refId = null
  subrefId = null
  questionLoadCallback = null
  helpTextLoadCallback = null

  constructor(params) {
    makeObservable(this, {
      questionData: computed,
      canRenderSpecificComponent: computed,
      uuid: computed,
      componentName: computed,
      includeSubref: computed,
      questionText: computed,
      helpText: computed,
      props: computed,
      hideQuestionInput: computed,
      withChrome: computed,
      showHeader: computed,
      showReviewArea: computed,
      isTableFooter: computed,
      isTableHeader: computed,
      showQuestionText: computed
    })
    this.questionLoadCallback = params.questionLoadCallback || function () {}
    this.helpTextLoadCallback = params.helpTextLoadCallback || function () {}
    this.options = params.options
    this.refId = params.refId
    this.subrefId = params.subrefId
    this.serviceUuid = params.serviceUuid

    this.service = WebWorksheetsService.lookup(this.serviceUuid)

    when(() => {
      return this.questionText && this.questionText != ''
    }, () => {
      this.questionLoadCallback(this.questionText)
    })

    when(() => {
      return this.helpText && this.helpText != ''
    }, () => {
      this.helpTextLoadCallback(this.helpText)
    })
  }

  get hideQuestionInput () {
    return this.options.hideInput
  }

  get withChrome () {
    return !this.includeSubref && !this.options.hideChrome && !this.isTableFooter
  }

  get showHeader () {
    return !this.includeSubref && !this.options.hideChrome && !this.options.hideHeader && !this.isTableFooter
  }

  get showReviewArea () {
    return !(this.options && (this.options.hideQuestionText || this.options.disableCommenting)) &&
      !this.includeSubref && // TableCell
      !this.isTableFooter && // TableFooter
      !this.isTableHeader && // TableHeader
      this.service.latestAssignmentResponse
  }

  get isTableFooter () {
    return this.options.type === 'TableFooter'
  }

  get isTableHeader () {
    return this.options.type === 'TableHeader'
  }

  get showQuestionText () {
    return !(this.options && this.options.hideQuestionText) && !this.includeSubref && !this.isTableFooter
  }


  get componentName () {
    const type = this.service.refIdToType(this.refId)
    if (this.options.type === 'TableCellSum') {
      return 'WWTableCellSum'
    } else if (type === 'AssignmentQuestion::ShortResponse') {
      return 'WWShortResponse'
    } else if (type === 'AssignmentQuestion::LongResponse') {
      return 'WWLongResponse'
    } else if (type === 'AssignmentQuestion::Multiselect') {
      return 'WWMultiselect'
    } else if (type === 'AssignmentQuestion::Select') {
      return 'WWSelect'
    } else if (type === 'AssignmentQuestion::Radio') {
      return 'WWRadio'
    } else if (type === 'AssignmentQuestion::List') {
      return 'WWList'
    } else if (this.options.type === 'TableHeader') {
      return 'WWTableHeader'
    } else if (this.options.type === 'TableFooter') {
      return 'WWTableFooter'
    } else if (type === 'AssignmentQuestion::Data') {
      return 'WWTableCell'
    } else {
      return null
    }
  }

  get includeSubref () {
    return this.componentName === 'WWTableCell' || this.componentName === 'WWTableCellSum'
  }

  get questionText () {
    return this.questionData && this.questionData.attributes && this.questionData.attributes.text || ''
  }

  get helpText () {
    return this.questionData && this.questionData.attributes && this.questionData.attributes.help_text || ''
  }

  get questionData () {
    return (this.service.questionData && this.service.questionData.find(q => q.referenceName === this.refId)) || {}
  }

  get canRenderSpecificComponent () {
    return this.questionData && this.questionData.referenceName
  }

  get uuid () {
    return this.questionData.uuid
  }

  get props () {
    return {
      serviceUuid: this.serviceUuid,
      refId: this.refId,
      readonly: !this.service.forceAllowEdit && this.service.readonly,
      options: this.options,
      accessibilityUuid: this.uuid,
      ...this.includeSubref && { subrefId: this.subrefId },
    }
  }
}

export default observer({
  name: 'ww-question',
  components: {
    WWShortResponse,
    WWLongResponse,
    WWRadio,
    WWSelect,
    WWMultiselect,
    WWList,
    WWTableCell,
    WWTableCellSum,
    WWQuestionReviewerComments,
    WWTableHeader,
    WWTableFooter,
  },
  data () {
    return {
      vm: new ViewModel({
        refId: this.refId,
        subrefId: this.subrefId,
        serviceUuid: this.serviceUuid,
        options: this.options,
        questionLoadCallback: this.setQuestionText,
        helpTextLoadCallback: this.setHelpText
      }),
    }
  },
  methods: {
    setQuestionText (questionText) {
      const clearIntvl = setInterval(() => {
        if (this.$refs.marked) {
          this.$refs.marked.innerHTML = marked.parse(questionText)
        }

        clearInterval(clearIntvl)
      }, 20)
    },
    setHelpText (helpText) {
      const clearIntvl = setInterval(() => {
        const renderer = new marked.Renderer();
        renderer.paragraph = function(text) {
          return `<p class="help-text">${text}</p>`;
        };
        
        if (this.$refs.help_text) {
          this.$refs.help_text.innerHTML = marked(helpText, { renderer: renderer })
        }

        clearInterval(clearIntvl)
      }, 20)
    }
  },
  props: {
    refId: {
      type: String,
      required: true,
    },
    subrefId: {
      type: String,
    },
    serviceUuid: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>

<style scoped>

</style>
