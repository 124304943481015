import Model from '@models/Model'
import User from '@models/User'

class QuestionReviewerComment extends Model {
  constructor (initialData) {
    if (initialData.user) {
      initialData.user = new User(initialData.user)
    }

    super(initialData, {
      momentKeys: [
        'created_at',
        'updated_at',
      ],
    })
  }

  get exists () {
    return !!this.id
  }

  get sentTimeFormatted () {
    return this.created_at && this.created_at.format('MMM Do YY @ H:mma')
  }
}

export default QuestionReviewerComment
