<script>
import { WindowVariables } from '@utils'
import FlashMessages from '@services/FlashMessageService'
import WebWorksheetsService from '@services/webWorksheets/Service'

import { makeObservable, action, computed, observable } from 'mobx'

import { config as i18n } from '@utils/i18n'

class ViewModel {
  service = null

  constructor(params) {
    makeObservable(this, {
      lastSavedAt: computed,
      isDirty: computed,
      isBlank: computed,
      isReadonly: computed,
      isForceAllowEdit: computed,
      submitted: computed,
      allAssignmentResponses: computed,
      allAssignmentsResponsesData: computed,
      hideVersionSelector: computed,
      viewingAssignmentResponseId: computed,
      latestAssignmentResponse: computed,
      latestCourseStepReview: computed,
      secondsAgo: computed,
      saveMessage: computed,
      showSaveActions: computed,
      showSaveButton: computed,
      showSubmitButton: computed,
      hideEditButton: computed,
      viewingLatestAssignmentResponse: computed,
      courseStepReviewAfterLatestAssignmentResponse: computed,
      reviewText: computed,
      reviewStatus: computed,
      // editSubmission: action,
    })

    this.service = WebWorksheetsService.lookup(params.serviceUuid)
  }

// FROM MIXIN

  get lastSavedAt () {
    return this.service && this.service.lastSavedAt
  }

  get isDirty () {
    return this.service.isDirty
  }

  get isBlank () {
    return this.service.isBlank
  }

  get isReadonly () {
    return this.service.readonly
  }

  get isForceAllowEdit () {
    return this.service.forceAllowEdit
  }

  get submitted () {
    return this.service.assignmentResponseIsSubmitted
  }

  get viewingAssignmentResponseId () {
    return this.service.assignmentResponseId
  }

  get latestAssignmentResponse () {
    return this.service.latestAssignmentResponse
  }

  get latestCourseStepReview () {
    return this.service.courseStepReview
  }



  get secondsAgo () {
    return this.currentTime && this.lastSavedAt && parseInt(this.currentTime - this.lastSavedAt)
  }

  get saveMessage () {
    if (!this.viewingLatestAssignmentResponse || this.isReadonly || !this.lastSavedAt || !this.currentTime) {
      return ''
    }

    if (this.isDirty) {
      return i18n.t('application.web_worksheets.header_footer.waiting_on_autosave')
    } else {
      const secondsSecondTier = 15 * 60
      if (this.secondsAgo < 60) {
        return i18n.t('application.web_worksheets.header_footer.saved_a_few_seconds_ago')
      } else if (this.secondsAgo >= 60 && this.secondsAgo < secondsSecondTier) {
        const n = parseInt(this.secondsAgo / 60)
        const minuteText = n === 1
          ? i18n.t('application.common.minute')
          : i18n.t('application.common.minutes')
        return `${i18n.t('application.common.saved')} ${n} ${minuteText} ${i18n.t('application.common.ago')}`
      } else if (this.secondsAgo >= secondsSecondTier) {
        return i18n.t('application.common.saved')
      } else {
        console.error('else condition missing...', this.secondsAgo, this.currentTime, this.lastSavedAt)
      }
    }
  }

  get showSaveActions () {
    return WindowVariables.currentUser.studentForGroupId(WindowVariables.groupId) &&
      (!this.isReadonly || this.isForceAllowEdit) &&
      !this.isBlank
  }

  get showSaveButton () {
    return this.showSaveActions &&
      this.viewingLatestAssignmentResponse &&
      this.isDirty && !this.isSaving
  }

  get showSubmitButton () {
    return this.showSaveActions &&
      this.viewingLatestAssignmentResponse &&
      (!this.latestAssignmentResponse.isSubmitted || this.isForceAllowEdit)
  }

  get hideEditButton () {
    return !this.viewingLatestAssignmentResponse
  }

  get viewingLatestAssignmentResponse () {
    return this.latestAssignmentResponse && this.viewingAssignmentResponseId === this.latestAssignmentResponse.id
  }

  get courseStepReviewAfterLatestAssignmentResponse () {
    return this.latestCourseStepReview &&
      this.latestAssignmentResponse &&
      this.latestAssignmentResponse.isSubmitted &&
      this.latestAssignmentResponse.submitted_at <= this.latestCourseStepReview.created_at ? this.latestCourseStepReview : null
  }

  get reviewText () {
    return this.courseStepReviewAfterLatestAssignmentResponse && !this.isForceAllowEdit ?
      (
        this.courseStepReviewAfterLatestAssignmentResponse.isApproved ?
          i18n.t('application.common.completed') : (
            this.courseStepReviewAfterLatestAssignmentResponse.isUnderReview
            ? i18n.t('application.common.under_review')
            : i18n.t('application.common.questions_remaining')
          )
      ) : null
  }

  get reviewStatus () {
    if (this.courseStepReviewAfterLatestAssignmentResponse) {
      return `${i18n.t('application.web_worksheets.header_footer.reviewed_as')} ${this.reviewText} @ ${this.courseStepReviewAfterLatestAssignmentResponse.created_at.format('MM/DD/YY hh:mm')}`
    } else {
      return i18n.t('application.web_worksheets.header_footer.waiting_for_review')
    }
  }



    // END FROM MIXIN




  get allAssignmentResponses () {
    return this.service.allAssignmentResponses
  }

  get allAssignmentsResponsesData () {
    return this.allAssignmentResponses.map(ar => ({
      id: ar.id,
      created_at: ar.created_at,
    }))
  }

  get hideVersionSelector () {
    return this.isForceAllowEdit || this.allAssignmentResponses.length < 2 || WindowVariables.currentUser.studentForGroupId(WindowVariables.group.id)
  }


  editSubmission () {
    let confirmStatus = true
    if (this.courseStepReviewAfterLatestAssignmentResponse && this.courseStepReviewAfterLatestAssignmentResponse.isApproved) {
      confirmStatus = window.confirm(i18n.t('application.web_worksheets.header.edit_confirmation_warning'))
    }
    this.service.forceAllowEdit = confirmStatus
  }
}

export default {
  data () {
    return {
      mvm: new ViewModel({
        serviceUuid: this.serviceUuid,
      }),
      currentTime: null,
      isSaving: false,
    }
  },

  methods: {
    save () {
      this.isSaving = true
      return this.mvm.service.saveQuestionResponses()
        .then(resp => {
          this.isSaving = false
        })
    },
    submit () {
      return this.mvm.service.submitQuestionResponse()
        .then(() => {
          FlashMessages.success(i18n.t('application.web_worksheets.header_footer.flash.submitted_for_review'))
        }, () => {
          FlashMessages.error(i18n.t('application.web_worksheets.header_footer.flash.submitted_for_review_error'))
        })
    },
  },
  created () {
    setInterval(() => {
      this.currentTime = (new Date).getTime() / 1000.0
    }, 1000)
  },
  props: {
    serviceUuid: {
      type: String,
      required: true,
    },
  },
}
</script>
