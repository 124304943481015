<template>
  <div class="ww-table-question">
    <masked-input v-if="isFormattedCell"
        ref="focusable"
        @focus="handleFocus()"
        v-model="answerText"
        :mask="maskPattern"
        :placeholder="vm.placeholder"
        :disabled="readonly"
        :data-question-ref="refId"
        :data-question-subref="subrefId"
        :guide="false"
        style="background-color: #f7f8fa"
    />

    <textarea v-else
      ref="focusable"
      @focus="handleFocus()"
      v-model="answerText"
      :placeholder="vm.placeholder"
      :disabled="readonly"
      :rows="rows"
      :data-question-ref="refId"
      :data-question-subref="subrefId"
    />

  </div>
</template>

<script>
import WebWorksheetsService from '@services/webWorksheets/Service'
import { action, computed, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-vue'
import EventBus from "@services/GlobalEventBus";
import { createNumberMask } from 'text-mask-addons'
import maskedInput from 'vue-text-mask'


class ViewModel {
  refId = null
  subrefId = null
  serviceUuid = null
  service = null

  constructor(params) {
    makeObservable(this, {
      questionData: computed,
      subrefData: computed,
      placeholder: computed,
    })

    this.refId = params.refId
    this.subrefId = params.subrefId
    this.serviceUuid = params.serviceUuid
    this.service = WebWorksheetsService.lookup(this.serviceUuid)
  }

  get questionData () {
    return this.service.questionData && this.service.questionData.find(q => q.referenceName === this.refId)
  }

  get subrefData () {
    return this.service.questionData && this.questionData.metadata &&
      this.questionData.metadata.subrefs &&
      this.questionData.metadata.subrefs[this.subrefId]
  }

  get placeholder () {
    return this.subrefData && this.subrefData.placeholder
  }
}


export default observer({
  name: 'ww-table',
  components: {
    maskedInput
  },
  data () {
    return {
      vm: new ViewModel({
        refId: this.refId,
        subrefId: this.subrefId,
        serviceUuid: this.serviceUuid,
      }),
      answerText: null,
      isLoading: null,
      maskPattern: null,
    }
  },
  computed: {
    isFormattedCell(){
      return this.maskPattern !== null
    },
    rows () {
      const size = this.options.size || 'sm'
      if (size === 'xs') {
        return 2
      } else if (size === 'sm') {
        return 2
      } else if (size === 'md') {
        return 6
      } else if (size === 'lg') {
        return 10
      }
    },
  },
  watch: {
    answerText (newVal, oldVal) {
      if (newVal !== oldVal && this.isLoading === false) { // will not trigger on initial load
        this.$nextTick(() => {

          this.vm.service.updateQuestionData(this.refId, {
            answer: {
              [this.subrefId]: this.answerText,
            },
          })

          EventBus.dispatch('assignment-response-changed', {
            refId: this.refId,
            subrefId: this.subrefId,
            newValue: this.answerText,
          })
        })
      }
    },
  },
  methods: {
    handleFocus () {
      this.vm.service.captureFocus(this.refId, this.subrefId)
    },
    setFocus () {
      let refIdFromService = this.vm.service.retrieveFocus().refId
      let subrefIdFromService = this.vm.service.retrieveFocus().subrefId

      if (refIdFromService === this.refId && subrefIdFromService === this.subrefId) {
        this.$refs.focusable.focus()
      }
    },
    useWorkaroundToSetBackgroundColor(){
      this.$nextTick(() => {
        const backgroundColor = '#f7f8fa';
        let ancestor = this.$el.closest('.cc-fillable-cell');
        if (ancestor) {
          ancestor.style.backgroundColor = backgroundColor;
        }
      });
    },
    toMask(value) {
      switch (value) {
        case 'CURRENCY':
          return createNumberMask({
            prefix: '$',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2,
            integerLimit: 9,
            allowNegative: false,
            allowLeadingZeroes: false,
          });
        case 'INTEGER':
          return createNumberMask({
            prefix: '',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: false,
            integerLimit: 10,
            allowNegative: false,
            allowLeadingZeroes: false,
          });
        case 'DOUBLE':
          return createNumberMask({
            prefix: '',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2,
            integerLimit: 10,
            allowNegative: false,
            allowLeadingZeroes: false,
          });
        case 'PERCENTAGE':
          return createNumberMask({
            prefix: '',
            suffix: '%',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2,
            integerLimit: 3, // Up to 999%
            allowNegative: false,
            allowLeadingZeroes: false,
          });
        default:
          return null;
      }
    },
  },
  mounted () {
    this.isLoading = true;

    this.maskPattern = this.options.valueFormat ? this.toMask(this.options.valueFormat) : null;

    const res = this.vm.questionData && this.vm.questionData.response.localAnswerData
      ? this.vm.questionData.response.localAnswerData[this.subrefId]
      : ''
    this.answerText = res

    // compare component's subrefId/refId with the service's subrefId/refId. If they match, then grab input area and focus on it.
    this.setFocus()

    if (this.options.inputAriaPlaceholderText) {
      this.$refs.focusable.setAttribute('aria-placeholder', this.options.inputAriaPlaceholderText)
    }

    if (this.isFormattedCell){
      this.useWorkaroundToSetBackgroundColor();
    }

    this.isLoading = false;
  },
  props: {
    refId: {
      type: String,
      required: true,
    },
    subrefId: {
      type: String,
    },
    serviceUuid: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>

<style scoped>

</style>
