<template>
  <div class="ww-long-response-question">
    <textarea v-if="!hideInput"
      ref="focusable"
      @focus="handleFocus()"
      v-model="answerText"
      :placeholder="placeholder"
      :disabled="readonly"
      :rows="rows"
      :data-question-ref="refId"
      :aria-describedby="accessibilityUuid"
    />
  </div>
</template>

<script>
import Mixin from '@components/webWorksheets/WWGenericQuestionTypeMixin.vue'
import EventBus from "@services/GlobalEventBus";

export default {
  name: 'ww-long-response',
  mixins: [Mixin],
  data() {
    return {
      answerText: '',
      questionRefToCopyFrom: null,
    }
  },
  mounted() {
    this.questionRefToCopyFrom = this.options.questionRefToCopyFrom
    EventBus.subscribe('assignment-response-changed', (event) => this.handleAssignmentResponseChange(event))
  },
  computed: {
    placeholder () {
      return this.questionData.attributes.placeholder || this.$t('application.web_worksheets.long_response.type_your_answer_placeholder')
    },
    rows () {
      const size = this.options.size || 'sm'
      if (size === 'xxs') {
        return 1
      } else if (size === 'xs') {
        return 2
      } else if (size === 'sm') {
        return 3
      } else if (size === 'md') {
        return 6
      } else if (size === 'lg') {
        return 10
      }
    },
  },
  methods: {
    handleAssignmentResponseChange(event) {
      if (event.refId === this.questionRefToCopyFrom && event.questionType === 'WWLongResponse' && JSON.stringify(this.answerText) !== JSON.stringify(event.newValue)) {
        this.answerText = event.newValue;
      }
    },
  },
  watch: {
    answerText(newVal, oldVal){
      if (newVal !== oldVal && oldVal !== undefined) {
        this.$nextTick(() => {
          const changes = [{newValue: newVal, oldValue: oldVal}]

          const payload = {
            refId: this.refId,
            subrefId: this.subrefId,
            newValue: newVal,
            questionType: 'WWLongResponse',
            changes: changes
          }

          EventBus.dispatch('assignment-response-changed', payload)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
