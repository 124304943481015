const messages = []
const callbacks = []

const DEFAULT_CONFIG = {
  defaultPosition: 'toast-top-full-width',
  defaultType: 'info',
  defaultTimeout: 4000,
}

function processObjectData (type, data, title) {
  // if Object
  if (typeof data === 'object' && data.msg) {
    return {
      ...data,
      ...!data.type && { type: DEFAULT_CONFIG.defaultType },
      ...{ type },
      ...title && { title },
      ...!data.position && { position: DEFAULT_CONFIG.defaultPosition },
      ...!data.timeout && { timeout: DEFAULT_CONFIG.defaultTimeout },
      ...!data.closeOnHover && { closeOnHover: DEFAULT_CONFIG.defaultCloseOnHover },
    }
  }

  // if String
  return {
    msg: data.toString(),
    position: DEFAULT_CONFIG.defaultPosition,
    type: type || DEFAULT_CONFIG.defaultType,
    timeout: DEFAULT_CONFIG.defaultTimeout,
    closeOnHover: DEFAULT_CONFIG.defaultCloseOnHover,
  }
}

function addMessage (data) {
  if (callbacks.length > 0) {
    callAddCallbacks(data)
  } else {
    messages.push(data)
  }
}

function callAddCallbacks (data) {
  callbacks.forEach((cb) => {
    cb.add(data)
  })
}

function callRemoveCallbacks (data) {
  callbacks.forEach((cb) => {
    cb.remove(data)
  })
}

function registerCallbacks (addCb, removeCb) {
  callbacks.push({
    add: addCb,
    remove: removeCb,
  })
}

function warning (msg, title) {
  addMessage(processObjectData('warning', msg, title))
}

function info (msg, title) {
  addMessage(processObjectData('info', msg, title))
}

function notice (msg, title) {
  addMessage(processObjectData('notice', msg, title))
}

function error (msg, title) {
  addMessage(processObjectData('error', msg, title))
}

function success (msg, title) {
  addMessage(processObjectData('success', msg, title))
}

function alert (msg, title) {
  addMessage(processObjectData('info', msg, title))
}

function removeFlash (data) {
  callRemoveCallbacks(data)
}

export default {
  registerCallbacks,
  removeFlash,
  warning,
  success,
  info,
  notice,
  error,
  alert,
}