<template>
  <div class="ww-short-response-question">
    <template v-if="readonly">
      <div class="ww-short-response-readonly-answer">{{ answerText }}</div>
    </template>
    <template v-else>
      <input v-if="!hideInput"
        ref="focusable"
        @focus="handleFocus()"
        v-model="answerText"
        :placeholder="placeholder"
        :data-question-ref="refId"
        :aria-describedby="accessibilityUuid"
      />
    </template>
  </div>
</template>

<script>
import Mixin from '@components/webWorksheets/WWGenericQuestionTypeMixin.vue'

export default {
  name: 'ww-short-response',
  mixins: [Mixin],
  computed: {
    placeholder () {
      return this.questionData.attributes.placeholder || this.$t('application.web_worksheets.short_response.type_your_answer_placeholder')
    },
  },
}
</script>

<style scoped>
</style>
