<template>
  <scroll-fixed-header :fixed.sync="fixed" :threshold="topMeasure" :style="{ left: leftFixedOffset, width: headerWidth }">
    <div class="ww-assignment-header" :id="`ar-${mvm.viewingAssignmentResponseId}`" :class="{ 'ww-assignment-header--show-version-selector': !mvm.hideVersionSelector }">
      <div class="ww-assignment-header-top">
        <div class="ww-assignment-title">{{ this.assignmentTitle }}</div>
        <div class="assignment-header-buttons">
          <div class="assignment-header-links">
            <a class="assignment-header-link btn btn-outline-light" href="#interaction-input" :aria-label="$t('application.web_worksheets.header.view_conversation')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z"></path>
                <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z"></path>
              </svg>
            </a>
            <a v-if="!isBenchmarkStep" class="assignment-header-link" href="#give-feedback">
              <button class="btn btn-outline-light" href="#give-feedback">{{ $t('application.web_worksheets.header.give_feedback') }} <i style="opacity: .5" class="fa fa-arrow-circle-down" /></button>
            </a>
          </div>
          <div class="save-container">
            <div class="last-saved">{{ mvm.saveMessage }}</div>
            <button v-if="mvm.showSaveButton" class="" style="margin-right: 16px;" @click="save">
              <svg version="1.1" style="fill: #CCCCCC;enable-background:new 0 0 407.096 407.096;" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"	 width="16px" height="16px" viewBox="0 0 407.096 407.096" xml:space="preserve"><g><g><path d="M402.115,84.008L323.088,4.981C319.899,1.792,315.574,0,311.063,0H17.005C7.613,0,0,7.614,0,17.005v373.086			c0,9.392,7.613,17.005,17.005,17.005h373.086c9.392,0,17.005-7.613,17.005-17.005V96.032			C407.096,91.523,405.305,87.197,402.115,84.008z M300.664,163.567H67.129V38.862h233.535V163.567z"/>		<path d="M214.051,148.16h43.08c3.131,0,5.668-2.538,5.668-5.669V59.584c0-3.13-2.537-5.668-5.668-5.668h-43.08			c-3.131,0-5.668,2.538-5.668,5.668v82.907C208.383,145.622,210.92,148.16,214.051,148.16z"/>	</g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
            </button>

            <button v-if="mvm.showSubmitButton" class="btn btn-light" @click="submit">{{ $t('application.common.submit') }}</button>

            <button v-if="mvm.submitted && !mvm.isForceAllowEdit && !mvm.hideEditButton" class="btn btn-light" @click="mvm.editSubmission()">
              <div class="flex flex-row">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 pr-2" role="img">
                  <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                  <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                </svg>

                <div>{{ $t('application.web_worksheets.header.edit_submission') }}</div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div class="ww-assignment-header-bottom" v-if="mvm.latestAssignmentResponse && mvm.latestAssignmentResponse.isSubmitted">
        <div class="submitted-at">{{ $t('application.common.submitted') }} @ {{ mvm.latestAssignmentResponse && mvm.latestAssignmentResponse.submitted_at && mvm.latestAssignmentResponse.submitted_at.format('MM/DD/YY hh:mm') }}</div>
        <div class="review-status">{{ mvm.reviewStatus }}</div>


        <div class="version-container" v-if="!mvm.hideVersionSelector">
          <div class="viewing-label">{{ $t('application.web_worksheets.header.current_version') }}</div>
          <select v-model="assignmentResponseId" @change="onNewVersionSelect">
            <option v-for="ar in mvm.allAssignmentsResponsesData" :value="ar.id" :key="ar.id">{{ ar.created_at.format('MM/DD/YY @ hh:mm') }}</option>
          </select>
        </div>
      </div>
    </div>
  </scroll-fixed-header>
</template>

<script>
import Mixin from '@components/webWorksheets/WWHeaderFooterMixin.vue'
import { when, reaction } from 'mobx'
import { observer } from 'mobx-vue'

import ScrollFixedHeader from 'vuejs-scroll-fixed-header'
import Vue from 'vue'

Vue.use(ScrollFixedHeader)

export default observer({
  name: 'ww-header',
  mixins: [Mixin],
  data () {
    return {
      assignmentResponseId: null,
      topMeasure: 800,
      fixed: false,
      leftFixedOffset: 0,
      headerWidth: '100%',
      queuedDisposals: [],
      stepType: null,
    }
  },
  computed: {
    assignmentTitle () {
      return this.isBenchmarkStep ?
          this.$t('application.common.benchmark_action_step'):
          this.$t('application.common.action_step');
    },
    isBenchmarkStep () {
      return this.stepType === 'CourseStep::Benchmark'
    },
  },
  methods: {
    onNewVersionSelect (x) {
      this.mvm.service.setAssignmentResponseId(this.assignmentResponseId)
    },
  },
  created () {
    this.stepType = this.mvm.service.stepType

    setInterval(() => {
      if (this.$el && this.$el.getRoundingClientRect) {
        const rect = this.$el.getBoundingClientRect()
        const parentRect = this.$el.parentElement.getBoundingClientRect()
        const elTop = rect.top

        this.leftFixedOffset = parentRect.left + 'px'
        this.headerWidth = parentRect.width + 'px'

        if (elTop === 0 || elTop < 0) {
          return
        } else {
          this.topMeasure = window.pageYOffset + elTop
        }
      }
    }, 1000)

    when(() => {
      return this.mvm.service.allAssignmentResponses &&
        this.mvm.service.allAssignmentResponses.length > 0 &&
        this.mvm.service.assignmentResponseId
    }, () => {
      this.assignmentResponseId = this.mvm.service.assignmentResponseId
    })

    this.queuedDisposals.push(reaction(() => this.mvm.viewingAssignmentResponseId,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.assignmentResponseId = this.mvm.viewingAssignmentResponseId
        }
      }).dispose
    )
  },
  beforeDestroy () {
    this.queuedDisposals.forEach(d => d())
  },
})
</script>

<style scoped>

</style>
