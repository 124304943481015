<template>
  <div class="ww-long-response-question">
    <div v-for="(option, index) of options.options" :key="index">
      <input 
        type="radio"
        class="border-2 rounded-full border-gray-300 checked:border-blue-500 border-solid"
        v-model="answerText"
        :disabled="readonly"
        :data-question-ref="refId"
        :value="option[1]"
        :aria-describedby="accessibilityUuid"
        :id="id + '-' + index"
      />
      <label class="pl-3" :for="id + '-' + index">
        {{ option[0] }}
      </label>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

import Mixin from '@components/webWorksheets/WWGenericQuestionTypeMixin.vue'

export default {
  name: 'ww-radio',
  mixins: [Mixin],
  data () {
    return {
      id: uuid(),
    }
  },
  computed: {
  },
}
</script>

<style scoped>


</style>
