import { v4 as uuid } from 'uuid'
import { computed, observable, makeObservable, runInAction, action } from 'mobx'
import Vue from 'vue'
import {debounce, filter, find, has} from 'lodash-es'
import WWQuestion from '@components/webWorksheets/WWQuestion.vue'
import WWHeader from '@components/webWorksheets/WWHeader.vue'
import WWFooter from '@components/webWorksheets/WWFooter.vue'
import WebWorksheetsDataService from '@services/webWorksheets/DataService'
import { WindowVariables } from '@utils'
import WebWorksheetsRenderService from '@services/webWorksheets/RenderService'
import { config as i18n } from '@utils/i18n'

import FlashMessageService from '@services/FlashMessageService'

const INSTANCES = {}
const AUTOSAVE_INTERVAL_MS = 15 * 1000

window.pushQuestionUpdateRequest = function (opts = {}) {
  const { assignmentRef, questionRef, subref, answerText } = opts
  const inst = find(INSTANCES, i => i.assignmentRefId === assignmentRef)

  if (!inst || !assignmentRef || !questionRef || !answerText) {
    return false
  }

  if (!window.er_ww_data_bus) {
    window.er_ww_data_bus = []
  }

  window.er_ww_data_bus.push({
    serviceUuid: inst.uuid,
    service: inst,
    questionRef,
    questionType: inst.refIdToType(questionRef),
    questionSubref: subref,
    question: inst._findQuestionDataByRefId(questionRef),
    answerText,
  })

  console.log('[er_ww_data_bus] New value pushed', {
    serviceUuid: inst.uuid,
    service: inst,
    questionRef,
    questionType: inst.refIdToType(questionRef),
    questionSubref: subref,
    question: inst._findQuestionDataByRefId(questionRef),
    answerText,
  })

  return true
}

let dataBusDereg = undefined
if (!dataBusDereg) {
  dataBusDereg = setInterval(() => {
    if (!window.er_ww_data_bus) {
      return
    }
    while (window.er_ww_data_bus.length > 0) {
      const msg = window.er_ww_data_bus.shift()
      let answerData = { answer: msg.answerText }
      if (msg.questionType === 'AssignmentQuestion::Data') {
        answerData = { answer: { [msg.questionSubref]: msg.answerText } }
      }
      msg.service.updateQuestionData(msg.questionRef, answerData)
    }
  }, 50)
}


class WebWorksheetsService {
  _refId = null
  _subrefId = null
  _groupId = null
  _assignmentRefId = null
  _saveButtonApp = null
  _autosaveInterval = null
  _isSubmitting = false
  _forceEdit = false
  _courseStepId = null
  _userId = null
  _isDirty = false
  _isBlank = false
  conditionalElements = null

  static lookup (uuid) {
    return INSTANCES[uuid]
  }

  constructor (mode, options = {}) {
    makeObservable(this, {
      _forceEdit: observable,
      _courseStepId: observable,
      _userId: observable,
      _isDirty: observable,
      _isBlank: observable,
      allAssignmentResponses: computed,
      viewingAssignmentResponse: computed,
      questionReviewerComments: computed,
      latestAssignmentResponse: computed,
      courseStepId: computed,
      userId: computed,
      forceAllowEdit: computed,
      readonly: computed,
      isDirty: computed,
      isBlank: computed,
      questionData: computed,
      lastSavedAt: computed,
      courseStepReview: computed,
      isReviewed: computed,
      setAssignmentResponseId: action,
      updateQuestionData: action,
    })

    this.uuid = uuid()

    if (options.assignmentId) {
      this._assignmentId = options.assignmentId
    } else if (options.assignmentRefId) {
      this._assignmentRefId = options.assignmentRefId
    }

    this._el = options.el
    this._courseStepId = options.courseStepId
    this._userId = options.userId
    this._groupId = options.groupId
    this._stepStype = options.stepType

    this._renderService = new WebWorksheetsRenderService(mode)
    this._dataService = new WebWorksheetsDataService(this, {
      assignment: options.assignment,
      assignmentResponseId: options.assignmentResponse && options.assignmentResponse.id,
    })

    this._autosaveInterval = setInterval(() => {
      // console.log('dirty questions', this.questionData.filter(q => q.dirty))

      // if we're in edit mode (normal) or forced (edit submission) AND something is dirty
      if ((!this.readonly || this._forceEdit) && this.isDirty) {
        this.saveQuestionResponses()
          .then(() => {
            this._updateDirtyStatus()
          })
      }
    }, AUTOSAVE_INTERVAL_MS)

    window.wws = this

    INSTANCES[this.uuid] = this
  }

  dispose () {
    this._autosaveInterval && clearInterval(this._autosaveInterval)
    this._dataService.dispose()
  }

  get rootElement () {
    return this._el
  }

  get groupId () {
    return this._groupId
  }

  get stepType () {
    return this._stepStype
  }

  get assignmentId () {
    return this._assignmentId
  }

  get assignmentRefId () {
    return this._assignmentRefId
  }

  get assignmentResponseId () {
    return this._dataService.assignmentResponseId
  }

  setAssignmentResponseId (id) {
    this._dataService.setAssignmentResponseId(id)
  }

  get allAssignmentResponses() {
    return this._dataService.allAssignmentResponses || []
  }

  get viewingAssignmentResponse() {
    return this._dataService.assignmentResponse
  }

  get questionReviewerComments() {
    return filter(this._dataService.questionReviewerComments, qrc => !qrc.discarded_at)
  }

  get latestAssignmentResponse() {
    return this._dataService.latestAssignmentResponse
  }

  get courseStepId() {
    return this._courseStepId
  }

  get userId() {
    return this._userId
  }

  get assignmentResponseIsSubmitted () {
    return this._dataService.assignmentResponse && this._dataService.assignmentResponse.isSubmitted
  }

  set forceAllowEdit (e) {
    this._forceEdit = e
  }

  get forceAllowEdit() {
    return this._forceEdit
  }

  // LOGIC FOR READONLY

  // Logged in not as THE student
  // - CANNOT edit

  // On page for first time
  // - No assignment response model exists
  // - CAN edit

  // Returning to draft
  // - Assignment response model exists, but is not submitted
  // - CAN edit

  // Returning to submitted assignment
  // - Assignment response model exists and is submitted
  // - CANNOT edit

  // Returning to submitted assignment after grading rejected
  // - Assignment response model exists and is submitted
  // - Course step review model exists and is "question remaining"
  // - CAN edit

  // Returning to submitted assignment after grading approved
  // - Assignment response model exists and is submitted
  // - Course step review model exists and is "approved"
  // - CANNOT edit

  // Returning to submitted assignment that was submitted a second time after grading
  // - Assignment response model exists and is submitted
  // - Course step review model exists
  // - Assignment response model was submitted AFTER the response was created
  // - CANNOT edit

  // All Readonly Conditions
  // - if not student (this should always force readonly)
  // - if ar and ar.submitted_at and !csr
  // - if ar and ar.submitted_at and csr and csr.approved
  // - if ar and ar.submitted_at and csr and ar.submitted_at > csr.created_at

  // a = ar = assignment response exists
  // b = ar.submitted_at = assignment response submitted
  // c = csr = course step review exists
  // d = csr.approved = course step review approved
  // e = ar.submitted_at > csr.created_at = new submission after the previous review

  // SIMPLIFY
  // (a & b & !c) | (a & b & c & d) | (a & b & c & e)
  // (ab & !c) | (ab & c & d) | (ab & c & e)
  // ab & (!c | (c & d) | (c & e))
  // ab & (!c | (c & (d | e)))
  // ab & ((!c | c) & (!c | (d | e)))
  // ab & (!c | d | e)
  // >>>
  // ar and ar.submitted_at and (!csr or csr.approved or ar.submitted_at > csr.created_at)a

  get readonly() {
    const resp = this._dataService.assignmentResponse
    const review = this.courseStepReview
    const isMyAssignment = WindowVariables.currentUser.id === this.userId
    console.log('COMPUTING READONLY', this._renderService.forceReadonly, resp, review, resp && review && resp.submitted_at > review.created_at, isMyAssignment)
    return !!(this._renderService.forceReadonly || !isMyAssignment || (resp && resp.submitted_at && (!this.isReviewed || (review && review.isApproved) || (review && resp.submitted_at > review.created_at))))
  }

  get isDirty() {
    return this._isDirty // this.questionData && this.questionData.filter(q => q.dirty).length > 0    //this._isDirty
  }

  get isBlank() {
    return this._isBlank
  }

  get questionData() {
    return this._dataService.questionData
  }

  get lastSavedAt() {
    return this._dataService._lastSavedAt
  }

  get courseStepReview() {
    return this._dataService.courseStepReview
  }

  get assignmentStatus() {
    if (this._dataService.assignmentResponse && this._dataService.assignmentResponse.state == 'submitted') {
      return 'submitted'
    }

    if (this.courseStepReview) {
      if (this.courseStepReview.isApproved) {
        return 'approved'
      }
  
      if (this.courseStepReview.isNotApproved) {
        return 'notApproved'
      }
  
      if (this.courseStepReview.isUnderReview) {
        return 'underReview'
      }
  
      if (this.courseStepReview.isSurvey) {
        return 'survey'
      }
    }
  }

  get isReviewed() {
    return !!this.courseStepReview
  }

  populateMarkup (assignmentHtml) {
    assignmentHtml = assignmentHtml.replace(/STATIC_CONTENT_BASE/g, this._renderService.staticImageBaseUrl)

    // if there is a script tag in the file we need to extract it, put it into a real script element created by hand
    const matchingScript = assignmentHtml.match(/<script>([\s\S]*)<\/script>/)
    assignmentHtml = assignmentHtml.replace(/<script>[\s\S]*<\/script>/, '')

    let html = ''
    const headerId = `assignment-header-${this._assignmentRefId}`
    const footerId = `assignment-footer-${this._assignmentRefId}`
    if (this._renderService.renderHeader) {
      html += `<div class="assignment-header-container" id="${headerId}"></div>` +
      `<div id="ww-assignment-fragment-wrapper">` +
      assignmentHtml +
      `</div>` +
      `<div class="assignment-footer-container" id="${footerId}"></div>`
      // console.log('HTML', html)
    } else {
      html = assignmentHtml
    }

    this.rootElement.innerHTML = html

    if (matchingScript) {
      const newScript = document.createElement('script')
      const inlineScript = document.createTextNode(`(function () {
        var WW_EMBEDDED_STATE = {
          isMyAssignment: ${WindowVariables.currentUser.id === this.userId ? 'true' : 'false'},
          assignmentRef: '${this._assignmentRefId}',
        }

        ${matchingScript[1]}
      })()`)
      newScript.appendChild(inlineScript)
      this.rootElement.appendChild(newScript)
    }

    // initialize conditional form elements
    this.conditionalElements = this.rootElement.querySelectorAll('[data-event-options]')

    if (this._renderService.renderHeader) {
      const headerElem = this.rootElement.querySelector(`#${headerId}`)
      new Vue({
        i18n,
        el: headerElem,
        render: h => h(WWHeader, {
          props: { serviceUuid: this.uuid, }
        })
      })

      const footerElem = this.rootElement.querySelector(`#${footerId}`)
      new Vue({
        i18n,
        el: footerElem,
        render: h => h(WWFooter, {
          props: { serviceUuid: this.uuid, }
        })
      })
    }
  }

  hydrateQuestions () {
    const questions = this.rootElement.querySelectorAll('[data-question-ref]')
    return Array.from(questions).map(q => {
      const refId = q.attributes['data-question-ref'].value
      const subrefId = q.attributes['data-subref'] && q.attributes['data-subref'].value
      let options = q.attributes['data-options'] && JSON.parse(q.attributes['data-options'].value);

      return new Vue({
        i18n,
        el: q,
        render: h => h(WWQuestion, {
          props: { serviceUuid: this.uuid, refId, subrefId, options },
        })
      })
    })
  }

  rehydrate () {
    if (this._renderService.renderLoadingMarkup) {
      this.rootElement.innerHTML = '<div class="ww-loading-action-step">Loading Action Step...</div>'
    }
    if (this._renderService.harvestGroupId && !this.groupId) {
      this._groupId = this.rootElement.attributes['data-group-id'].value
    }
    if (this._renderService.harvestAssignmentRef && !this.assignmentRefId && !this.assignmentId) {
      this._assignmentRefId = this.rootElement.attributes['data-assignment-ref'].value
    }

    // this `parentElement` reference is BAD and should be replaced by some kind of argument showing the service where the shade is
    // this.rootElement.parentElement.classList.add('assignment-web-container--closed')
    return this._dataService.loadData()
      .then(() => {
        if (this._renderService.renderMarkup) {
          if (this._dataService.assignmentHtmlUrl) {
            return this._dataService.requestAssignmentRemoteHtml()
              .then(html => this.populateMarkup(html))
              .then(() => {
                this.hydrateQuestions()
              })
          } else {
            this.populateMarkup(this._dataService.assignmentHtmlFragment)
          }
        }
        // this is here to make question hydration work for course-content development
        this.hydrateQuestions()
      })
  }

  evaluateShowEvent(element) {
    const eventOptions = JSON.parse(element.attributes['data-event-options'].value)

    if (eventOptions.show) {
      const logicType = eventOptions.show.logicType
      const references = eventOptions.show.references
      let showElement = false

      references.forEach((ref) => {
        let value = null

        if (ref.questionRef) {
          const q = this._findQuestionDataByRefId(ref.questionRef)
          value = q.response.localAnswer
        }

        if (ref.variable) {
          if (ref.variable == "assignmentStatus") {
            value = this.assignmentStatus
          }
        }

        if (logicType === 'any') {
          showElement = showElement || value == ref.value || (value && value.id == ref.value)
        }
      })

      if (showElement) {
        element.classList.remove('hidden');
      } else {
        element.classList.add('hidden');
      }
    }
  }

  updateQuestionData (refId, data = {}) {
    const q = this._findQuestionDataByRefId(refId)
    const type = this.refIdToType(refId)

    // empty string is falsy, so if we ever want an empty answer to clear the localAnswer have to use has
    if (q && (data.answer || has(data, 'answer'))) {
      if (type === 'AssignmentQuestion::Data' || type === 'AssignmentQuestion::List') {
        // console.log('updateQuestonData AQ::Data', refId, cloneDeep(q.response.localAnswerData), cloneDeep(data),'new data', Object.assign(q.response.localAnswerData || {}, data.answer))
        q.response.localAnswerData = Object.assign({}, q.response.localAnswerData || {}, data.answer)
        // console.log(cloneDeep(q.response.localAnswerData), cloneDeep(data.answer), cloneDeep(q.response.localAnswerData))
      } else {
        q.response.localAnswer = data.answer
      }
      // console.log('QUESTION UPDATED', q)
    }

    setTimeout(() => {
      this.conditionalElements.forEach((element) => {
        this.evaluateShowEvent(element)
      })
    }, 100)

    this._updateDirtyStatusDebounced()
  }

  createAssignmentQuestionResponse (assignmentResponseId, assignmentQuestionId) {
    return this._dataService.createAssignmentQuestionResponse(assignmentResponseId, assignmentQuestionId)
  }

  addReviewerComment (payload) {
    return this._dataService.createQuestionReviewerComment(payload)
  }

  deleteReviewerComment (commentId) {
    return this._dataService.deleteReviewerComment(commentId)
  }

  refIdToType (refId) {
    const q = this._findQuestionDataByRefId(refId)
    return q ? q.type : null
  }

  captureFocus (refId, subrefId) {
    this._refId = refId
    this._subrefId = subrefId
  }

  retrieveFocus () {
    return { refId: this._refId, subrefId: this._subrefId }
  }

  // Save a draft of the question responses
  saveQuestionResponses () {
    return this._dataService.saveQuestionResponses()
      .then(() => {
        return this._updateDirtyStatus()
      }, () => {
        FlashMessageService.error({ timeout: 15000, msg: 'Your assignment responses failed to save :-( Please copy your answers and refresh the page.' })
      })
  }

  // If there is a draft, submit it. If there is no draft,
  // make one and then submit it.
  async submitQuestionResponse () {
    if (this._isSubmitting) { return false }
    this._isSubmitting = true
    try {
      await this.saveQuestionResponses()
      await this._dataService.submitAssignmentResponse()

      setTimeout(() => {
        this.conditionalElements.forEach((element) => {
          this.evaluateShowEvent(element)
        })
      }, 100)

      this._forceEdit = false
    } catch (err) {

    }
    this._isSubmitting = false
  }

  // Private

  _findQuestionDataByRefId (refId) {
    return this.questionData && this.questionData.find(q => q.referenceName === refId)
  }

  _updateDirtyStatus () {
    runInAction(() => {
      this._isDirty = this.questionData.filter(q => q.dirty).length > 0
      this._isBlank = this.questionData.filter(q => q.blank).length === this.questionData.length
    })
  }

  _updateDirtyStatusDebounced = debounce(() => { this._updateDirtyStatus(); }, 300);
}

export default WebWorksheetsService