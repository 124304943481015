import dayjs from '@services/dayjsWrapper'
import { WindowVariables } from '@utils'
class NewModel {
  _attributes = {}
  _relationships = {}

  constructor (initialData, included = [], {
    columns = [
      // Key names of properties that are attributes of the model
      // e.g. ['name', 'description', 'created_at']
    ],
    momentKeys = [
      // Key names of properties that will be converted into Moment objects
      // e.g. ['start_date', 'end_date']
    ],
    relationships = {
      // Property names and the class object used to instantiate its model
      // e.g. { 'event': { collection: true|false, model: EventModelClass } }
    },
    debug = false,
  }) {
    this._debug = debug
    this._columnConfig = columns
    this._dateKeyConfig = momentKeys
    this._relationshipConfig = relationships

    this._rawData = initialData
    this._included = included

    Object.assign(this, initialData.attributes, {})

    this._dateKeyConfig.forEach(dt => {
      if (this[dt]) {
        this[dt] = dayjs(this[dt]).tz(WindowVariables.timezone)
      }
    })

    this.relationships = Object.keys(relationships).reduce((accum, rel) => {
      const relationshipConfig = relationships[rel]
      const incomingRelationshipData = initialData.relationships && initialData.relationships[rel]
      if (!incomingRelationshipData || !incomingRelationshipData.data) {
        return accum
      }

      if (relationshipConfig.collection) {
        accum[rel] = included.filter(inc => {
          return incomingRelationshipData.data && incomingRelationshipData.data.find(i => i.id === inc.id)
        }).map(inc => {
          const foundRec = inc
          const model = relationshipConfig.polymorphic ? relationshipConfig.polymorphic[foundRec.type] : relationshipConfig.model
          const foundRecModel = foundRec && model && new model(foundRec, included)
          return foundRecModel || foundRec
        })
      } else {
        const foundRec = included.find(inc => incomingRelationshipData.data && incomingRelationshipData.data.id === inc.id) || null
        const model = relationshipConfig.polymorphic ? relationshipConfig.polymorphic[foundRec.type] : relationshipConfig.model
        const foundRecModel = foundRec && model && new model(foundRec, included)
        accum[rel] = foundRecModel || foundRec
      }

      return accum
    }, {})
  }

  getRelation (key) {
    return this.relationships[key]
  }

  get attributes () { return this._attributes }
  // get relationships () { return this._relationships }

  toJson () {
    return {
      ...this._columnConfig.reduce((accum, col) => {
        accum[col] = this[col]
        return accum
      }, {}),
      ...this.relationships,
    }
  }

  DEBUG () {
    if (this._debug) {
      console.log('DEBUG:', ...arguments)
    }
  }

  get _isModel() {
    return true
  }
}

export default NewModel
