const MODE_MAP = {
  renderHeader: {
    'leon': false,
    'timeline-modal': false,
    'course-step': true,
  },
  harvestAssignmentRef: {
    'leon': true,
    'timeline-modal': false,
    'course-step': true,
  },
  harvestGroupId: {
    'leon': false,
    'timeline-modal': false,
    'course-step': true,
  },
  renderLoadingMarkup: {
    'leon': false,
    'timeline-modal': true,
    'course-step': true,
  },
  renderMarkup: {
    'leon': false,
    'timeline-modal': true,
    'course-step': true,
  },
  renderRemoteMarkup: {
    'leon': false,
    'timeline-modal': false,
    'course-step': false,
  },
  renderMarkupFromModel: {
    'leon': false,
    'timeline-modal': false,
    'course-step': false,
  },
  baseUrl: {
    'leon': 'http://localhost:3001',
    'timeline-modal': '/api',
    'course-step': '/api',
  },
  forceReadonly: {
    'leon': false,
    'timeline-modal': true,
    'course-step': false,
  },
  disableEditing: {
    'leon': false,
    'timeline-modal': true,
    'course-step': false,
  },
  staticImageBaseUrl: {
    'leon': 'http://localhost:4001',
    'timeline-modal': import.meta.env.VITE_COURSE_CONTENT_HOST_URL + '/static',
    'course-step': import.meta.env.VITE_COURSE_CONTENT_HOST_URL + '/static',
  },
}

class WebWorksheetsRenderService {
  _mode = null

  constructor (mode) {
    this._mode = mode
  }

  get mode () {
    return this._mode
  }

  get renderHeader () {
    return this._getModeFlag('renderHeader')
  }

  get harvestAssignmentRef () {
    return this._getModeFlag('harvestAssignmentRef')
  }

  get harvestGroupId () {
    return this._getModeFlag('harvestGroupId')
  }

  get renderMarkup () {
    return this._getModeFlag('renderMarkup')
  }

  get renderLoadingMarkup () {
    return this._getModeFlag('renderLoadingMarkup')
  }

  get baseUrl () {
    return this._getModeFlag('baseUrl')
  }

  get forceReadonly () {
    return this._getModeFlag('forceReadonly')
  }

  get staticImageBaseUrl () {
    return this._getModeFlag('staticImageBaseUrl')
  }

  get disableEditing () {
    return this._getModeFlag('disableEditing')
  }

  _getModeFlag (flag) {
    return MODE_MAP[flag][this.mode]
  }
}

export default WebWorksheetsRenderService
