import Model from '@models/Model'

class CourseStepReview extends Model {
  constructor (initialData) {
    super(initialData, {
      momentKeys: [
        'created_at',
        // 'updated_at',  //turn off for perf. unused.
      ],
    })
  }

  get isApproved () {
    return this.status === 1
  }

  get isNotApproved () {
    return this.status === 0
  }

  get isUnderReview () {
    return this.status === 2
  }

  get sentTimeFormatted () {
    return this.created_at.format('MMM Do YY @ H:mma')
  }

  get isSurvey () {
    return this.type === 'CourseStepReview::Survey'
  }
}

export default CourseStepReview
