<template>
  <div class="ww-long-response-question">
    <div v-for="(option, index) in options.options" :key="index">
      <input
        class="border-2 border-solid border-gray-300"
        :data-question-ref="refId"
        :value="option[1]"
        :id="`checkbox-${option[1]}`"
        type="checkbox" 
        v-model="answerText"
      >
      <label class="pl-3" :for="`checkbox-${option[1]}`">{{ option[0] }}</label>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Mixin from '@components/webWorksheets/WWGenericQuestionTypeMixin.vue'

export default {
  name: 'ww-multiselect',
  components: {
    Multiselect,
  },
  mixins: [Mixin],
  data () {
    return {
      answerText: [],
      multiValue: [],
    }
  },

  // we need to convert this.options.options to [{ id: opt[1], label: opt[0] }]
  // need to look at docs for read-only mode when `readonly`
  // look at docs for how to use multiselect mode for <multiselect> - I think we do it elsewhere in app
  // create
  // `multiValue` needs to serialize value to `answerText` shown on the mixin
  // need to preset `multiValue` to the `answerText` when `answerText` is loaded.
  // computed: {
  //   selectOptions() {
  //     if (this.options.options) {
  //       return this.options.options.map((option) => {
  //         return {
  //           id: option[1],
  //           label: option[0]
  //         }
  //       })
  //     } else {
  //       return []
  //     }
  //   }
  // },
  mounted () {
    if (this.questionData.response.localAnswer && this.questionData.response.localAnswer ) {
      const savedAnswers = this.questionData.response.localAnswer.split(',')
      this.answerText = this.options.options.filter((a) => savedAnswers.includes(a[1])).map(a => a[1])
    } else {
      this.answerText = []
    }
  }
}
</script>

<style scoped>

</style>
