import Vue from 'vue'
import LIB from 'vue-i18n'

Vue.use(LIB)

let i18n = null
if (window.embeddedTranslations) {
  i18n = new LIB({
    locale: window.embeddedTranslations.locale,
    messages: window.embeddedTranslations.translations,
  })
} else {
  i18n = new LIB({
    locale: 'en',
    messages: {},
  })
}

export let VueI18n = LIB
export let config = i18n
