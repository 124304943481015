import dayjs from '@services/dayjsWrapper'
import { WindowVariables } from '@utils'

class Model {
  constructor (initialData, { momentKeys = [] }) {
    for (let key of Object.keys(initialData)) {
      this[key] = initialData[key]
    }

    for (let key of momentKeys) {
      if (this[key]) {
        this[key] = dayjs(this[key]).tz(WindowVariables.timezone)
        // this[key] = moment.utc(this[key]).tz(WindowVariables.timezone)
      }
    }
  }

  get _isModel() {
    return true
  }
}

export default Model
