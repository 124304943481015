import NewModel from '@models/NewModel'

class AssignmentQuestionResponse extends NewModel {
  constructor (initialData, included) {
    super(initialData, included, {
      momentKeys: [
        'created_at',
        'updated_at',
      ],
      columns: [
        'id',
        'type',
        'assignment_question_id',
        'assignment_response_id',
        'answer',
        'answer_data',
      ],
    })
  }
}

export default AssignmentQuestionResponse
