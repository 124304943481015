<template>
  <div class="ww-table-footer">
    <div class="ww-question-review-comments-wrapper" v-if="showReviewArea">
      <WWQuestionReviewerComments :serviceUuid="serviceUuid" :refId="refId" />
    </div>
  </div>
</template>

<script>
import WebWorksheetsService from '@services/webWorksheets/Service'
import WWQuestionReviewerComments from '@components/webWorksheets/WWQuestionReviewerComments.vue'

import { observer } from 'mobx-vue'

export default observer({
  name: 'ww-table-footer',
  components: {
    WWQuestionReviewerComments,
  },
  data () {
    return {
      service: WebWorksheetsService.lookup(this.serviceUuid),
    }
  },
  computed: {
    showReviewArea () {
      return this.service.latestAssignmentResponse
    },
  },
  props: {
    refId: {
      type: String,
      required: true,
    },
    serviceUuid: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>

<style scoped>

</style>
