import User from '@models/User'
import NewModel from '@models/NewModel'

class Attachment extends NewModel {
  constructor (initialData, included) {
    super(initialData, included, {
      columns: [
        'id',
        'file_type',
        'presigned_url',
        'file_name',
        'file_identifier',
        'created_by_id',
        'discarded_at',
        'attachment_relations',
      ],
      momentKeys: [
        'created_at',
        // 'updated_at',  //turn off for perf. unused.
      ],
      relationships: {
        created_by: {
          model: User,
        }
      }
    })
  }

  get sentTimeFormatted () {
    return this.created_at.format('MMM Do YY @ H:mma')
  }
}

export default Attachment
