<template>
  <div class="ww-table-header">
  </div>
</template>

<script>
import WebWorksheetsService from '@services/webWorksheets/Service'

export default {
  name: 'ww-table-header',
  data () {
    return {
      service: WebWorksheetsService.lookup(this.serviceUuid),
    }
  },
  props: {
    refId: {
      type: String,
      required: true,
    },
    serviceUuid: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>

</style>
