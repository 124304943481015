<script>
import { debounce } from 'lodash-es'

import WebWorksheetsService from '@services/webWorksheets/Service'

export default {
  data () {
    return {
      service: WebWorksheetsService.lookup(this.serviceUuid),
      answerText: null,
    }
  },
  methods: {
    onChangePage (page) {
      this.$emit('change-page', page)
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    handleFocus () {
      this.service.captureFocus(this.refId, this.subrefId)
    },
    setFocus () {
      let refIdFromService = this.service.retrieveFocus().refId

      if (refIdFromService === this.refId) {
        this.$refs.focusable.focus()
      }
    }
  },
  computed: {
    questionData () {
      return this.service.questionData.find(q => q.referenceName === this.refId)
    },
    localAnswer () {
      return this.service.questionData.find(q => q.referenceName === this.refId) &&
        this.service.questionData.find(q => q.referenceName === this.refId).response.localAnswer
    },
    hideInput () {
      return this.options.hideInput
    },
    shouldUpdateService () {
      return !this.options.skipServiceUpdate
    },
  },
  watch: {
    answerText (newVal, oldVal) {
      if (!this.subrefId && this.shouldUpdateService && (newVal !== oldVal) || this.answerText instanceof Array) {
        this.debouncedUpdateQuestionData(this.refId, {
          answer: this.answerText
        })
      }
    },
    localAnswer (newVal, oldVal) {
      if (!this.shouldUpdateService) {
        this.answerText = newVal
      }
    }
  },
  mounted () {
    this.answerText = this.questionData.response.localAnswer

    this.debouncedUpdateQuestionData = debounce(function (refId, data) {
      this.service.updateQuestionData(refId, data)
    }, 250)

    // compare component's subrefId/refId with the service's subrefId/refId. If they match, then grab input area and focus on it.
    this.setFocus()
  },
  props: {
    refId: {
      type: String,
      required: true,
    },
    serviceUuid: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    accessibilityUuid: {
      type: String,
    }
  },
}
</script>
