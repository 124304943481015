import NewModel from '@models/NewModel'
import Attachment from '@models/Attachment'
import AssignmentQuestionResponse from '@models/AssignmentQuestionResponse'
import AssignmentResponseGrade from '@models/AssignmentResponseGrade'

class AssignmentResponse extends NewModel {
  constructor (initialData, included) {
    super(initialData, included, {
      columns: [
        'id',
        'user_id',
        'group_id',
        'assignment_id',
        'created_at',
        'file_identifier',
        'discarded_at',
        'user',
        'submitted_at',
        'assignment_type',
      ],
      momentKeys: [
        'submitted_at',
        'created_at',
        // 'updated_at',  //turn off for perf. unused.
      ],
      relationships: {
        assignment_response_grade: {
          model: AssignmentResponseGrade,
        },
        attachments: {
          model: Attachment,
          collection: true,
        },
        assignment_question_responses: {
          model: AssignmentQuestionResponse,
          collection: true,
        }
      },
    })
  }

  get exists () {
    return !!this.id
  }

  get sentTimeFormatted () {
    return this.isSubmitted && this.submitted_at.format('MMM Do YY @ H:mma')
  }

  get filename () {
    return this.attachments && this.attachments.length > 0
      ? this.attachments[0].file_name
      : null
  }

  get attachmentUrl () {
    return this.attachments && this.attachments.length > 0
      ? this.attachments[0].presigned_url
      : null
  }

  get uploaderName () {
    return this.user.firstLastName
  }

  get isWebWorksheet () {
    return this.file_identifier === 'worksheet'
  }

  get isPdf () {
    return !this.isWebWorksheet
  }

  get isSubmitted () {
    return !!this.submitted_at
  }

  appendAssignmentResponse (aqr) {
    this._relationships.assignment_question_responses = [...this._relationships.assignment_question_responses, aqr]
  }
}

export default AssignmentResponse
