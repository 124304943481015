class CellFormatter {
    static CURRENCY = 0;
    static INTEGER = 1;
    static DOUBLE = 2;
    static PERCENTAGE = 3;

    static applyFormatting(value, formatType){
        switch(formatType){
            case CellFormatter.CURRENCY: return CellFormatter.#formatInputAsCurrency(value);
            case CellFormatter.INTEGER: return CellFormatter.#formatInputAsInteger(value);
            case CellFormatter.DOUBLE: return CellFormatter.#formatInputAsDouble(value);
            case CellFormatter.PERCENTAGE: return CellFormatter.#formatInputAsPercentage(value);
        }
    }

    static removeFormatting(value){
        return value.replace(/[^0-9.]+/g, '');
    }

    static #formatInputAsCurrency(value) {
        const formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',minimumFractionDigits: 2,maximumFractionDigits: 2});
        const result = parseFloat(CellFormatter.removeFormatting(value), '') || 0;
        return formatter.format(result);
    }

    static #formatInputAsInteger(value) {
        const formatter = new Intl.NumberFormat('en-US', {maximumFractionDigits: 0});
        const result = parseInt(value.replace(/[^0-9]+/g, ''), 10) || 0;
        return formatter.format(result);
    }

    static #formatInputAsDouble(value) {
        const formatter = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2,maximumFractionDigits: 2});
        const result = parseFloat(CellFormatter.removeFormatting(value), '') || 0;
        return formatter.format(result);
    }

    static #formatInputAsPercentage(value) {
        const formatter = new Intl.NumberFormat('en-US', {style: 'percent',minimumFractionDigits: 2,maximumFractionDigits: 2});
        // Assuming input is like '99.99' for 99.99%, we need to divide by 100 for correct formatting
        const result = (parseFloat(CellFormatter.removeFormatting(value), '') || 0) / 100;
        return formatter.format(result);
    }
}

export default CellFormatter;