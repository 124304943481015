import Model from '@models/Model'
import dayjs from '@services/dayjsWrapper'
import { WindowVariables } from '@utils'

function convertTimestamp(val) {
  if (val) {
    return dayjs(val).tz(WindowVariables.timezone)
  } else {
    return null
  }
}
class GroupMembership extends Model {
  constructor (initialData) {

    initialData.engagement_metadata.last_engagement_timestamp = convertTimestamp(initialData.engagement_metadata.last_engagement_timestamp)
    initialData.engagement_metadata.last_workshop_attended_at = convertTimestamp(initialData.engagement_metadata.last_workshop_attended_at)
    initialData.engagement_metadata.last_office_hours_attended_at = convertTimestamp(initialData.engagement_metadata.last_office_hours_attended_at)
    initialData.engagement_metadata.last_page_load_event_timestamp = convertTimestamp(initialData.engagement_metadata.last_page_load_event_timestamp)
    initialData.engagement_metadata.last_er_staff_engagement_timestamp = convertTimestamp(initialData.engagement_metadata.last_er_staff_engagement_timestamp)
    initialData.engagement_metadata.last_action_step_submitted_timestamp = convertTimestamp(initialData.engagement_metadata.last_action_step_submitted_timestamp)
    initialData.engagement_metadata.completed_pace_risk = Number(initialData.engagement_metadata.completed_pace_risk)
    initialData.engagement_metadata.completed_phase_buffer_days = Number(initialData.engagement_metadata.completed_phase_buffer_days)

    super(initialData, {
      momentKeys: [],
    })
  }

  get isActive() {
    return this.state === 1
  }

  get isTeacher() {
    return this.role === 'teacher'
  }

  get isMentor() {
    return this.role === 'mentor'
  }

  get isStudent() {
    return this.role === 'student'
  }
}

export default GroupMembership
