import Model from '@models/Model'
import { includes } from 'lodash-es'

class Group extends Model {
  constructor(initialData) {
    super(initialData, {
      momentKeys: [
        'created_at',
        'updated_at',
      ],
    })
  }

  get isEntrepreneurGroup() {
    return this.group_type === 'entrepreneur'
  }

  get isEducationGroup() {
    return this.group_type === 'education'
  }

  get hasStarterTrackingFeature() {
    return includes(this.group_features, 'starter_tracking')
  }

  get hasMentoringFeature() {
    return includes(this.group_features, 'mentoring')
  }

  get hasGradebookFeature() {
    return includes(this.group_features, 'gradebook')
  }

  get hasModuleGatingFeature() {
    return includes(this.group_features, 'module_gating')
  }

  get mentorDisplayName() {
    return 'Mentor'
  }

  get studentDisplayName() {
    return this.isEducationGroup ? 'Student' : 'Entrepreneur'   // change is isEducationGroup??
  }

  get teacherDisplayName() {
    return this.isEducationGroup ? 'Teacher' : 'Trainer'
  }
}

export default Group
