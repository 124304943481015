<template>
  <div class="ww-short-response-question">
    <div class="mb-2" v-for="index in computedNumberOfInputs" :key="index">
      <input
        ref="focusable"
        @focus="handleFocus()"
        v-model="answerText[index - 1]"
        :disabled="readonly"
        :data-question-ref="refId"
        :aria-describedby="accessibilityUuid"
      />
    </div>
  </div>
</template>

<script>
import Mixin from '@components/webWorksheets/WWGenericQuestionTypeMixin.vue'
import EventBus from "@services/GlobalEventBus";

export default {
  name: 'ww-list',
  mixins: [Mixin],
  data() {
    return {
      answerText: [],
      oldAnswerText: [], //exists only to compare changes
      questionRefToCopyFrom: null,
      isLoading: true,
    }
  },
  computed: {
    computedNumberOfInputs() {
      // Use the prop value or the default
      return this.options.numberOfInputs || 5;
    },
    shouldCopyDataFrom() {
      return this.questionRefToCopyFrom !== null;
    }
  },
  mounted() {
    this.isLoading = true;
    this.answerText = [];
    this.questionRefToCopyFrom = this.options.questionRefToCopyFrom

    EventBus.subscribe('assignment-response-changed', (event) => this.handleAssignmentResponseChange(event))

    if (this.questionData.response.localAnswerData) {
      const localAnswerData = this.questionData.response.localAnswerData
      Object.getOwnPropertyNames(localAnswerData).forEach((item) => {
        this.answerText.push(localAnswerData[item])
      })
    }

    this.answerText = [
      ...this.answerText,
      ...Array.from({ length: this.computedNumberOfInputs - this.answerText.length }, () => '')
    ];

    this.oldAnswerText = this.answerText.slice();
    this.isLoading = false;
  },
  methods: {
    handleAssignmentResponseChange(event) {
      if (event.refId === this.questionRefToCopyFrom && event.questionType === 'WWList' && JSON.stringify(this.answerText) !== JSON.stringify(event.newValue)) {
        this.oldAnswerText = this.answerText.slice();
        this.answerText = event.newValue.slice();
      }
    }
  },
  watch: {
    answerText: {
      deep: true,
      handler(newVal) {
        const oldVal = this.oldAnswerText
        if (newVal !== oldVal && this.isLoading === false) { // will not trigger on initial load
          this.$nextTick(() => {
            const changes = newVal
                .map((item, index) => { return {index, newValue: item, oldValue: oldVal[index]} })
                .filter(item => item.oldValue !== undefined)
                .filter(item => item.newValue !== item.oldValue)

            const payload = {
              refId: this.refId,
              subrefId: this.subrefId,
              newValue: this.answerText.slice(),
              questionType: 'WWList',
              changes: changes
            }

            EventBus.dispatch('assignment-response-changed', payload)
            this.oldAnswerText = newVal.slice();
          })
        }
      },
    },
  }
}
</script>

<style scoped>

</style>
