<template>
  <div class="ww-question-reviewer-comment" :class="{ 'is-old': isOldAssignmentResponse, 'edit-mode': mode === 'edit' }">
    <template v-if="mode === 'display'">
      <div class="sender-name">{{ senderName }}</div>
      <div class="time">
        @ {{ comment.created_at.format('M/D/YY h:mma') }}
      </div>
      <div class="message" v-html="comment.message.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
      <div class="edit-btn" @click="enableEditMode" v-if="comment.reviewed_by_user.id === currentUser.id"><i class="fa fa-pencil" /></div>
      <div class="delete-btn" @click="deleteComment" v-if="comment.reviewed_by_user.id === currentUser.id"><i class="fa fa-close" /></div>
    </template>
    <template v-else>
      <div class="send-container" v-if="canCreateComment">
        <textarea
          class="textbox"
          v-model="comment.message"
          type="text"
          :title="$t('application.web_worksheets.question_reviewer_comment.edit_your_message')"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="send"
          @keydown.enter.shift.exact="newline"
        />
        <button class="send-message" :class="{ 'with-text': comment.message.length > 0 }" @click="attemptToSaveComment">
          {{ $t('application.web_worksheets.question_reviewer_comment.save') }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import WebWorksheetsService from '@services/webWorksheets/Service'
import { WindowVariables } from '@utils'
import QuestionReviewerComments from '@dataServices/QuestionReviewerComments'
import FlashMessages from '@services/FlashMessageService'
import { computed, makeObservable } from 'mobx'
import { observer } from 'mobx-vue'

class ViewModel {
  serviceUuid = null
  
  constructor(params) {
    makeObservable(this, {
      viewingAssignmentResponseId: computed,
    })

    this.serviceUuid = params.serviceUuid

    this.service = WebWorksheetsService.lookup(this.serviceUuid)
  }

  get viewingAssignmentResponseId () {
    return this.service.assignmentResponseId
  }
}

export default observer({
  name: 'ww-question-reviewer-comment',
  data () {
    return {
      vm: new ViewModel({
        serviceUuid: this.serviceUuid,
      }),
      mode: 'display',
      currentUser: WindowVariables.currentUser,
      isSubmitting: false,
    }
  },
  methods: {
    send () {
      this.attemptToSaveComment()
    },
    newline () {
      this.comment.message = `${this.comment.message}\n`
    },
    enableEditMode () {
      this.mode = 'edit'
    },
    attemptToSaveComment () {
      if (this.comment.message.trim().length === 0 || this.isSubmitting) {
        return false
      }

      return this.updateComment()
    },
    updateComment () {
      this.isSubmitting = true
      QuestionReviewerComments.update({
        id: this.comment.id,
        message: this.comment.message,
      })
        .then(() => {
          this.isSubmitting = false
          FlashMessages.success('Comment updated.')
          this.mode = 'display'
        }, () => {
          this.isSubmitting = false
          FlashMessages.error('Unable to update comment.')
        })
    },
    deleteComment () {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.vm.service.deleteReviewerComment(this.comment.id)
              .then(() => {
                FlashMessages.success('Comment deleted.')
                // this.mode = 'display'
              }, () => {
                FlashMessages.error('Unable to delete comment.')
              })
          }
        }
      })
    }
  },
  computed: {
    canCreateComment () {
      return !WindowVariables.currentUser.studentForGroupId(this.vm.service.groupId)
    },
    isOldAssignmentResponse () {
      return this.comment.assignment_response_id < this.vm.viewingAssignmentResponseId
    },
    senderName () {
      return WindowVariables.currentUser.id === this.comment.reviewed_by_user.id ?
        'You' :
        `${this.comment.reviewed_by_user.first_name} ${this.comment.reviewed_by_user.last_name}`
    }
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    serviceUuid: {
      type: String,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
  .ww-question-reviewer-comment {
    margin-bottom: 4px;
    padding: 2px 8px;
    border-radius: 4px;
    background: #b3d2f2;

    &.discarded {
      display: none;
    }

    &.edit-mode {
      padding-left: 0;
      padding-right: 0;
    }

    &.is-old {
      .message {
        color: #555;
      }
    }

    .time {
      display: inline;
      color: #666666;
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 1.6rem;
      letter-spacing: -1px;
    }

    .sender-name {
      display: inline;
      color: #222;
      font-weight: 500;
    }

    .message {
      display: inline;
      margin-left: 0.4rem;
      color: black;
    }

    .edit-btn, .delete-btn {
      display: inline;
      margin-left: 0.4rem;
      cursor: pointer;
    }



    .send-container {
      // padding-top: 2px;
      display: flex;
      justify-content: center;
      width: 100%;
      position: relative;

      input, textarea {
        flex: 1 1 auto;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #3F3F3F;
          font-weight: 500;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #3F3F3F;
          font-weight: 500;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: #3F3F3F;
          font-weight: 500;
        }
      }

      button.send-message {
        background-color: #888888;
        cursor: not-allowed;

        &.with-text {
          background-color: #573f7e;
          cursor: pointer;
        }
      }

      .send-message {
        background-color: #573f7e;
        color: #fff;
        width: 48px;
        // height: 48px;
        border-radius: 0px 5px 5px 0px;
        border: none;

        &:hover {
          background-color: #4e3871;
          transition: all 500ms ease;
          border-radius: 0px 8px 8px 0px;
        }
      }
    }
  }

</style>
