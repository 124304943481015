import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)

export default dayjs