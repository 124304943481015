import { map } from 'lodash-es'
import axios from './axios'

import AssignmentResponse from '@models/AssignmentResponse'

const baseUrlString = '/assignment_responses'

async function getAll (params, opts = {}) {
  return axios.get(baseUrlString, { params })
    .then(response => {
      return map(response.data.data, r => initWithData(r, response.data.included))
    })
    .catch(console.error)
}

async function getById (id, opts = {}) {
  return axios.get(baseUrlString + '/' + id)
    .then(response => {
      return initWithData(response.data.data, response.data.included)
    })
    .catch(error => Promise.reject(error.response.data))
}

async function create (formData) {
  // this returns an ARRAY of assignmentResponses
  //  because if I discard old assignmentResponses I need to tell
  //  the frontend that it is discarded now
  return axios.post(baseUrlString, formData)
    .then(response => map(response.data.data, r => initWithData(r, response.data.included)))
    .catch(error => Promise.reject(error.response.data))
}

async function remove (id) {
  return axios['delete'](`${baseUrlString}/${id}`)
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(console.error)
}

function initWithData (data, included) {
  return new AssignmentResponse(data, included)
}

export default {
  getAll,
  getById,
  initWithData,
  create,
  remove
}
