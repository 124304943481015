import { map, omit } from 'lodash-es'
import axios from './axios'

import QuestionReviewerComment from '@models/QuestionReviewerComment'

const baseUrlString = '/question_reviewer_comments'

async function getAll (params) {
  return axios.get(baseUrlString, { params })
    .then(response => map(response.data, initWithData))
    .catch(console.error)
}

async function create (formData) {
  return axios.post(baseUrlString, formData)
    .then(response => initWithData(response.data))
    .catch(error => Promise.reject(error.response.data))
}

async function update (formData) {
  return axios.put(`${baseUrlString}/${formData.id}`,{ question_reviewer_comment: omit(formData, ['id']) })
    .then(response => initWithData(response.data))
    .catch(error => Promise.reject(error.response.data))
}

async function remove (id) {
  return axios['delete'](`${baseUrlString}/${id}`)
    .then(response => initWithData(response.data))
    .catch(console.error)
}

function initWithData (data) {
  return new QuestionReviewerComment(data)
}

export default {
  getAll,
  initWithData,
  create,
  update,
  remove
}
