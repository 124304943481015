<template>
  <div class="ww-table-question">
    <textarea
      ref="focusable"
      @focus="handleFocus()"
      v-model="answerText"
      :placeholder="vm.placeholder"
      :disabled="true"
      :rows="rows"
      :data-question-ref="refId"
      :data-question-subref="subrefId"
    />
  </div>
</template>

<script>
import WebWorksheetsService from '@services/webWorksheets/Service'
import { action, computed, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-vue'
import EventBus from "@services/GlobalEventBus";
import CellFormatter from "@services/webWorksheets/CellFormatter";

class ViewModel {
  refId = null
  subrefId = null
  serviceUuid = null
  service = null

  constructor(params) {

    makeObservable(this, {
      questionData: computed,
      subrefData: computed,
      placeholder: computed,
    })

    this.refId = params.refId
    this.subrefId = params.subrefId
    this.serviceUuid = params.serviceUuid
    this.service = WebWorksheetsService.lookup(this.serviceUuid)
  }

  get questionData () {
    return this.service.questionData && this.service.questionData.find(q => q.referenceName === this.refId)
  }

  get subrefData () {
    return this.service.questionData && this.questionData.metadata &&
      this.questionData.metadata.subrefs &&
      this.questionData.metadata.subrefs[this.subrefId]
  }

  get placeholder () {
    return this.subrefData && this.subrefData.placeholder
  }
}


export default observer({
  name: 'ww-table',
  props: {
    refId: {type: String,required: true,},
    subrefId: {type: String,},
    serviceUuid: {type: String,required: true,},
    readonly: {type: Boolean,required: false,},
    options: {type: Object,default: () => ({}),},
  },
  data () {
    return {
      vm: new ViewModel({
        refId: this.refId,
        subrefId: this.subrefId,
        serviceUuid: this.serviceUuid,
      }),
      answerText: null,

      sumType: null,
      sourceTableCellValues: {},
      sourceTableQuestionRefId: null,
      sourceTableColumnNumber: null,
      formatType: null,
    }
  },
  mounted () {
    this.startListeningToSourceTableChanges();

    this.answerText = this.vm.questionData && this.vm.questionData.response.localAnswerData?
                      this.vm.questionData.response.localAnswerData[this.subrefId] :
                      ''

    this.setFocus() // compare component's subrefId/refId with the service's subrefId/refId. If they match, then grab input area and focus on it.

    if (this.options.inputAriaPlaceholderText) {
      this.$refs.focusable.setAttribute('aria-placeholder', this.options.inputAriaPlaceholderText)
    }
  },
  computed: {
    rows () {
      const size = this.options.size || 'sm'
      if (size === 'xs') {
        return 2
      } else if (size === 'sm') {
        return 2
      } else if (size === 'md') {
        return 6
      } else if (size === 'lg') {
        return 10
      }
    },
    isCountRows () {
      return this.sumType === 'COUNT_ROWS'
    },
    isSumContent () {
      return this.sumType === 'SUM_CONTENT'
    },
  },
  methods: {
    startListeningToSourceTableChanges(){
      this.sourceTableQuestionRefId = this.options.sourceTableQuestionRef
      this.sourceTableColumnNumber = this.options.sourceTableColumn
      this.sumType = this.options.sumType
      const valueFormat = this.options.valueFormat
      if ((!this.isCountRows && !this.isSumContent) || this.sourceTableQuestionRefId == null || this.sourceTableColumnNumber == null || valueFormat == null) {
        console.error('Error mounting WWTableCellSum vue component! sumType, sourceTableQuestionRef, sourceTableColumn, and valueFormat are required options! sumType:', this.sumType, 'sourceTableQuestionRef:', this.sourceTableQuestionRefId, 'sourceTableColumn:', this.sourceTableColumnNumber, 'valueFormat:', valueFormat)
      }
      this.formatType = this.toFormatType(valueFormat)

      EventBus.subscribe('assignment-response-changed', (event) => this.handleAssignmentResponseChange(event))
    },
    handleFocus () {
      this.vm.service.captureFocus(this.refId, this.subrefId)
    },
    setFocus () {
      let refIdFromService = this.vm.service.retrieveFocus().refId
      let subrefIdFromService = this.vm.service.retrieveFocus().subrefId

      if (refIdFromService === this.refId && subrefIdFromService === this.subrefId) {
        this.$refs.focusable.focus()
      }
    },
    handleAssignmentResponseChange(event){

      if (this.shouldProcessAssignmentResponseChange(event)) {
        this.$set(this.sourceTableCellValues, event.subrefId, event.newValue);
        const values = Object.values(this.sourceTableCellValues);
        const sum = this.calculateSum(values)
        this.answerText = CellFormatter.applyFormatting(sum.toString(), this.formatType)
      }
    },
    shouldProcessAssignmentResponseChange(event){
      return event.refId === this.sourceTableQuestionRefId
              && this.shouldListenToThisSubrefColumn(event.subrefId, this.sourceTableColumnNumber)
    },
    shouldListenToThisSubrefColumn(subRefId, columnNumber){
      //expects format like: "R01C01__yaddayadda", "R01C02__yaddayadda", "R01C03__yaddayadda", "R02C01__yaddayadda", "R02C02__yaddayadda", "R02C03__yaddayadda"
      const subRefColumnNumberStart = subRefId.indexOf('C');
      const subRefColumnNumberEnd = subRefId.indexOf('__');
      const subRefColumnNumber = subRefId.substring(subRefColumnNumberStart + 1, subRefColumnNumberEnd);
      return parseInt(subRefColumnNumber, 10) === parseInt(columnNumber, 10);
    },
    calculateSum(values){
      if (this.isCountRows) {
        return this.calculateCountRows(values)
      } else if (this.isSumContent) {
        return this.calculateSumContent(values)
      } else {
        console.error('Error calculating sum! sumType is neither COUNT_ROWS or SUM_CONTENT! sumType:', this.sumType)
        return 0
      }
    },
    calculateSumContent(values){
      return values
          .map(value => CellFormatter.removeFormatting(value))
          .map(value => parseFloat(value) || 0)
          .reduce((acc, value) => acc + value, 0)
    },
    calculateCountRows(values){
      return values
            .filter(value => value != null)
            .filter(value => value.trim().length > 0)
            .length
    },
    toFormatType(value) {
      switch (value) {
        case 'CURRENCY':
          return CellFormatter.CURRENCY;
        case 'INTEGER':
          return CellFormatter.INTEGER;
        case 'DOUBLE':
          return CellFormatter.DOUBLE;
        case 'PERCENTAGE':
          return CellFormatter.PERCENTAGE;
        default:
          return CellFormatter.INTEGER;
      }
    },
    updateQuestionData() {
      this.vm.service.updateQuestionData(this.refId, {
        answer: {
          [this.subrefId]: this.answerText,
        },
      })
    },
  },
  watch: {
    answerText (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateQuestionData()
      }
    },
  },
})
</script>

<style scoped>

</style>
