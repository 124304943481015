import Model from '@models/Model'

class AssignmentResponseGrade extends Model {
  constructor (initialData) {
    super(initialData, {
      momentKeys: [
        'created_at',
        'updated_at',
      ],
    })
  }
}

export default AssignmentResponseGrade
