<template>
  <div class="ww-long-response-question">
    <multiselect
      :data-question-ref="refId"
      :id="refId"
      v-model="answerText"
      label="label"
      track-by="id"
      :options="selectOptions">
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Mixin from '@components/webWorksheets/WWGenericQuestionTypeMixin.vue'

// see WWMultiselect notes. don't need the multiValue setup though... should just be able to use answerText directly
// will need to convert options.options...

export default {
  name: 'ww-select',
  components: {
    Multiselect,
  },
  mixins: [Mixin],
  computed: {
    selectOptions() {
      if (this.options.options) {
        return this.options.options.map((option) => {
          return {
            id: option[1],
            label: option[0]
          }
        })
      } else {
        return []
      }
    }
  },
  mounted () {
    const selectedOption = this.questionData.response.localAnswer

    if (!this.options.options) {
      return
    }

    const result = this.options.options.filter((a) => a[1] === selectedOption)

    if (result.length > 0) {
      this.answerText = {label: result[0][0], id: result[0][1]}
    }
  }
}
</script>

<style scoped>

</style>
