<template>
  <div class="ww-question-reviewer-comments">
    <div class="comments-container">
      <WWQuestionReviewerComment v-for="comment in vm.comments" :key="comment.id" :comment="comment" :serviceUuid="serviceUuid" />

      <div class="ww-no-comments" v-if="vm.comments.length === 0 && !canCreateComment">
        {{ $t('application.web_worksheets.question_reviewer_comments.no_reviewer_comments') }}
      </div>
    </div>

    <div class="send-container" v-if="canCreateComment">
      <textarea
        class="textbox"
        v-model="commentMessage"
        type="text"
        :placeholder="inputPlaceholder"
        :title="$t('application.web_worksheets.question_reviewer_comments.send_a_message')"
        @keydown.enter.exact.prevent
        @keyup.enter.exact="send"
        @keydown.enter.shift.exact="newline"
      />
      <button class="send-message" :class="{ 'with-text': commentMessage.length > 0 && !isSubmitting }" @click="attemptToCreateComment">
        {{ $t('application.web_worksheets.question_reviewer_comments.send') }}
      </button>
    </div>
  </div>
</template>

<script>
import { WindowVariables } from '@utils'
import WWQuestionReviewerComment from '@components/webWorksheets/WWQuestionReviewerComment.vue'
import WebWorksheetsService from '@services/webWorksheets/Service'
import FlashMessageService from '@services/FlashMessageService'

import { computed, makeObservable } from 'mobx'
import { observer } from 'mobx-vue'

class ViewModel {
  serviceUuid = null
  service = null
  refId
  
  constructor(params) {
    makeObservable(this, {
      viewingAssignmentResponseId: computed,
      questionData: computed,
      allComments: computed,
      comments: computed,
      uuid: computed,
    })

    this.serviceUuid = params.serviceUuid
    this.refId = params.refId
    this.service = WebWorksheetsService.lookup(this.serviceUuid)
  }

  get viewingAssignmentResponseId () {
    return this.service.assignmentResponseId
  }

  get questionData () {
    return this.service.questionData && this.service.questionData.find(q => q.referenceName === this.refId) || {}
  }

  get allComments () {
    return this.service.questionReviewerComments || []
  }

  get comments () {
    return this.allComments.filter(c => c.assignment_question_id === this.questionData.id)
  }

  get uuid () {
    return this.questionData.uuid
  }
}

export default observer({
  name: 'ww-question-reviewer-comments',
  components: {
    WWQuestionReviewerComment,
  },
  data () {
    return {
      vm: new ViewModel({
        serviceUuid: this.serviceUuid,
        refId: this.refId,
      }),
      commentMessage: '',
      inputPlaceholder: this.$t('application.web_worksheets.question_reviewer_comments.make_a_comment_placeholder'),
      isSubmitting: false,
    }
  },
  methods: {
    newline () {
      this.commentMessage = `${this.commentMessage}\n`
    },
    send () {
      this.attemptToCreateComment()
    },
    attemptToCreateComment () {
      if (this.commentMessage.trim().length === 0 || this.isSubmitting) {
        return false
      }
      this.isSubmitting = true

      const aqr = this.vm.service.viewingAssignmentResponse && this.vm.service.viewingAssignmentResponse.getRelation('assignment_question_responses').find(aqr => aqr.assignment_question_id === this.vm.questionData.id)
      if (!aqr) {
        this.vm.service.createAssignmentQuestionResponse(this.vm.service.latestAssignmentResponse.id, this.vm.questionData.id)
          .then(this.createComment)
      } else {
        return this.createComment(aqr)
      }
    },
    createComment (aqr) {
      if (!aqr) {
        return false
      }
      return this.vm.service.addReviewerComment({
        message: this.commentMessage,
        assignment_question_response_id: aqr && aqr.id,
      }).then(resp => {
        this.commentMessage = ''
        this.isSubmitting = false
        FlashMessageService.success(this.$t('application.web_worksheets.question_reviewer_comments.flash.comment_success'))
      }, resp => {
        FlashMessageService.error(this.$t('application.web_worksheets.question_reviewer_comments.flash.comment_error'))
      })
    },
  },
  computed: {
    canCreateComment () {
      return !WindowVariables.currentUser.studentForGroupId(this.vm.service.groupId)
    },
    props () {
      return {
        serviceUuid: this.serviceUuid,
        refId: this.refId,
        options: this.options,
      }
    },
  },
  props: {
    refId: {
      type: String,
      required: true,
    },
    serviceUuid: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>

<style lang="scss" scoped>
.ww-question-reviewer-comments {
  // background: #F0F2F6;
  background: hsl(220deg, 25%, 90%);
  padding: 8px 8px 8px 8px;
  border-radius: 0 0 4px 4px;

  .ww-no-comments {
    color: #666666;
    font-size: 0.8rem;
    text-align: center;
    text-transform: uppercase;
  }
}

.send-container {
  // padding-top: 2px;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  input, textarea {
    flex: 1 1 auto;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #3F3F3F;
      font-weight: 500;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #3F3F3F;
      font-weight: 500;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #3F3F3F;
      font-weight: 500;
    }
  }

  button.send-message {
    background-color: #888888;
    cursor: not-allowed;

    &.with-text {
      background-color: #573f7e;
      cursor: pointer;
    }
  }

  .send-message {
    background-color: #573f7e;
    color: #fff;
    width: 48px;
    // height: 48px;
    border-radius: 0px 5px 5px 0px;
    border: none;

    &:hover {
      background-color: #4e3871;
      transition: all 500ms ease;
      border-radius: 0px 8px 8px 0px;
    }
  }
}

.textbox {
  background-color: #fff;
  padding: 8px;
  color: #333;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e8e8e8;

  &:focus {
    background-color: #fff;
    border-color: 1px solid #573f7e;
  }
}

</style>
