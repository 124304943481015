import axios from 'axios'

const baseApiUrl = '/api'
const axiosInstance = axios.create()

// check if csrf exists; if not, don't halt execution - for course-content
const metaCsrfEl = document.querySelector('meta[name="csrf-token"]')

axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
if (metaCsrfEl) {
  axiosInstance.defaults.headers.common['X-CSRF-Token'] = metaCsrfEl.getAttribute('content')
}
axiosInstance.defaults.baseURL = baseApiUrl

export default axiosInstance
